@import '../../../../../scss/dark/mixin';
.oXjalm27Ks{
    &-link{
        cursor: pointer;
        user-select: none;
        border-radius: 5px;
        min-height: 36px;
        display: flex; 
        align-items: center;
        .material-icons{
            font-size: 20px;
            transform: none;
            padding: 0.5rem;
            color: #86b9e6;
        } 
        &:hover{
            color: #ffffff;
            background: #151a27;
        }
        &:active{
            transform: scale(0.97);
        }
    }
    &-notis{
        .oXjalm27Ks-num{
            font-size: 10px;
            bottom: 0px;
            left: 0px;
            background: #6f1442;
            padding: 3px 4px 3px 4px;
            border-radius: 3px;
            margin-right: -5px;
            margin-left: 0.70rem;
            transform: translate(0px, 0px) !important;
            display: inline-block !important;
        }
    }
}
.nUTyx{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 8px;
    &:hover{
        color: #ffffff;
        background: #151a27;
    }
    &:active{
        transform: scale(0.97);
    }
    &-photo{
        width: 35px;
        height: 35px;
        display: inline-block;
        border-radius: 4px;
        background-position: center;
        background-size: cover;
    }
    &-name{
        display: none;
    }
}
.create-shout-content{
    @include _FORM();
    .btn-outline-secondary{
        border-color: #0a0e17 !important;
        background: #6dbaff14;
        color: #9cbcdf;
        &:hover{
            background: lighten($color: #294962, $amount: 5);
        }
        &:active{
            background: lighten($color: #1976d2, $amount: 8) !important;
        }
    }
}

.shout-clears{
    background: transparent;
    border: none;
    outline: none;
    border-radius: 3px;
    outline-color: transparent;
    &:hover{
        background: lighten($color: #151a27, $amount: 8);
    }
    &:active{
        background: lighten($color: #151a27, $amount: 12) !important;
    }
    svg{
        color: #cfe6ff7a;
    }
}