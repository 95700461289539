.page-editor{
    .text-muted {
        color: #83baff !important;
    }
    .nav-tabs{
    }
    .panel_portada{
        .portada{
            border-radius: 5px;
            background-size: cover;
            background-position: center;
        }
    }
    .themain{
        .titulo{
        }
    }
    .tabs23{
        padding: 10px 15px 15px 15px;
        margin-bottom: 10px;
    }
    .enviar_boton_ojawgj{
        .icon{
            transform: translate(0px, 4px);
        }
        .isMobile &{
            margin: 0px auto;
            width: 95%;
            //margin-bottom: px;
        }
    }
    .panel23{
        .isMobile &{
            &.purpure{
                margin: 0px auto;
                width: 95%;
            }
        }
    }
}
.tox-statusbar__branding{
    display: none;
}

.etiquetas23{
    li{
        display: inline-block;
        //background: #6f1342;
        //padding: 3px 7px;
        border-radius: 5px;
        margin-right: 7px;
        //margin-bottom: 2px;
        max-width: 100%;
        word-wrap: break-word;
        overflow: hidden;
        cursor: pointer;
    }
}
.sources23{
    .page-post &{
        margin-bottom: -2px;
    }
    label{
        color: #aadcff85;
        margin: 0px;
    }
    li{
        display: block;
        //background: #18273a;
        //padding: 3px 7px;
        border-radius: 5px;
        color: lighten($color: #aadcff85, $amount: 10);
        margin-right: 0px;
        margin-bottom: 7px;
        max-width: 100%;
        word-wrap: break-word;
        max-height: 45px;
        overflow: hidden;
        cursor: pointer;
        &.ninguna{
            background: #2b4156;
            color: #869cb1;
        }
        a{
            color: inherit;
            overflow-wrap: anywhere;;
        }
    }
}

.EditorContainer{
    .message{
        display: none;
    }
    button[title="Pantalla completa"]{
        //display: none;
    }
    .isMobile &{
        .__editor__{
            //max-height: 300px !important;
            //overflow: hidden;
            button[title="Pantalla completa"]{
                display: inherit;
            }
        }
        .message{
            display: block;
            margin-top: 10px;
            color: #aaa;
            user-select: none;
        }
    }
}

.fhawoihfawh{
    margin-left: 0px !important;
}

.tox-form__grid-32col{
    > .tox-form__group:nth-child(1){
        width: calc(100% / 3 - (8px / 2));
    }
    > .tox-form__group:nth-child(2){
        width: calc(100% / 1.5 - (8px / 2));
    }
}
button[title="Subir Imagen"]{
    margin-left: 0px !important;
}