
._comment_{
    transition: margin-left 500ms;

    &:nth-last-child(1){
        .content{
            border-bottom: 0px;
            margin-bottom: 0px;
        }
    }

    .replys &{
        margin-left: calc(50px + .75rem + .5rem);
        .isMobile &{
            margin-left: 30px;
        }

        .button.reply{
            //display: none !important;
        }
    }

    &._editor_{
        // margin-top: 30px;
        // .isNano &{
        //     > a{
        //         display: none;
        //     }
        // }
        .tox-editor-container{
            border-radius: 10px;
        }
        .content{
            border-bottom: 0px;
        }
        textarea{
            margin-top: 6px;
            transition: height min-height 500ms;
            .isNotMobile &{
                height: 100px;
                min-height: 100px;
                &:focus{
                    min-height: 135px;
                }
            }
            .isMobile &{
                height: 80px;
                min-height: 80px;
                &:focus{
                    min-height: 130px;
                }
            }
        }
        .footer{
            padding: 25;
            .boton_editor{
                position: relative;
                color: #fff;
                padding: 0.25rem 0.5rem;
                font-size: 0.8203125rem;
                line-height: 1.5;
                border-radius: 0.2rem;
                border: 1px s;
                cursor: pointer;
                .icon{
                    font-size: 13px;
                    position: absolute;
                    transform: translate(0px, 3px);
                }
                .text{
                    padding-left: 20px;
                }
            }
        }
    }

    .content{
        .___body___{
            margin-top: .3rem;
            margin-bottom: .3rem;
        }
        .header{
            width: 100%;
            span.onlineball{
                display: inline-block;
                height: 5px;
                width: 5px;
                margin-bottom: 2px;
                margin-right: 2px;
                border-radius: 99px;
            }
        }
        .body .form-group{
            margin: 0;
        }
        .footer{
            .LinkIrAlPost{
                span{
                    @media (max-width: 340px)
                    {
                        display: none;
                    }

                }
            }
        }

    }

}


.BtnFoo{
    display: inline-block;
    .padre{
        display: table;
        .hijo{
            display: table-cell;
            height: 25px;
            transition: 80ms;
            min-width: 25px;
            vertical-align: top;
            div{
                border-width: 1px;
                border-style: solid;
                height: 25px;
            }
            ._r{
                border-right-width: 0px;
            }
        }
    }
    ._r{
        cursor: pointer;
        border-radius: 5px 0px 0px 5px ;
        position: relative;
        width: 25px;
        height: 25px;
        border-right-width: 0px;

        svg{
            width: 14px;
            height: 13px;
            transform: translate(5px, 0px);
            position: absolute;
            bottom: 5px;
        }
    }
    ._l{
        border-radius: 0px 5px 5px 0px;
        overflow: hidden;
        text-align: center;
        padding-left: 3px;
        padding-right: 4px;
        span{
            font-size: 14px;
            transform: translate(0px,-1px);
            display: inline-block;
        }
    }
    &.reply{
        ._r{
            border-radius: 5px 5px 5px 5px ;
            border-right-width: 1px !important;
            width: 37px;
            svg{
                transform: translate(10px, -1px);
            }
        }
        ._l{
            display: none;
        }
    }
}

.LinkIrAlPost{
    transform: translate(-5px, -3px);
    display: inline-block;
}

.simple_tooltip{
    .dropdown-menu{
        box-shadow: #00000047 0px 0px 10px;
        > a{
            text-decoration: none;
        }
    }
    .dropdown-menu[data-show] {
        display: block;
    }
    .more{
        .material-icons.puntos{
            font-size: 15px;
            transform: translate(-3px,-0px);
            user-select: none;
            cursor: pointer;
            padding: 4px;
        }
    }
    button{
        &.icon24{
            position: relative;
            // display: inline-block;
            // height: 30px;
            // width: 30px;
            // margin: -0.25rem 0px;
            padding: 0.35rem 1.5rem 0.15rem 1.5rem;
            padding-left: 39px;
        }
        .icon25{
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// .replys > ._comment_:nth-last-child(1){
//     .button.reply{
//         //display: inline-block !important;
//     }
//     border-bottom: 1px solid;
// }
