.logo-event-trabajador{
    &-corbata{
        position: absolute;
        left: -18px;
        top: 3px;
        height: 17px;
        object-fit: cover;
        transform: rotate(22deg);
    }
    &-casco{
        position: absolute;
        left: -1px;
        bottom: 13px;
        height: 7px;
        object-fit: cover;
        opacity: 30;
    }
    &-maletin{
        position: absolute;
        left: -9px;
        bottom: -4px;
        height: 11px;
        object-fit: cover;
        opacity: 30;
    }
}