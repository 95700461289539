
@use 'sass:math';

.componen_loading{
    // text-align: center;
    .conteiner{
        color: $text_color_url;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .lds-ellipsis {
        div {
            background: $text_color_url;
        }
    }
}

.photo_shadow{
    @include _PHOTO_SHADOW(1);
}
.photo_shadow0{
    @include _PHOTO_SHADOW(0);
}


@mixin _PERFIL_DARK {
    .perfil{
        .photo-container{
            @include _PHOTO_SHADOW();
            .perfil-editar{
                color: #ffbadc;
                background: rgb(110, 21, math.div(66, 0.9));
                border-radius: 5px;
                transition: 300ms;
                user-select: none;
                &:hover{
                    background-color: lighten($color: rgb(110, 21, math.div(66, 0.9)), $amount: 5);
                    color: #fff;
                }
                &:active{
                    background-color: lighten($color: rgb(110, 21, math.div(66, 0.9)), $amount: 8);
                }
            }
            .lds-circle{
                    >div{
                        background-color: #ffbadc;
                    }
            }
        }

        .name{
            color: $text_color_user;
        }
        .username{
            color: $text_color_user_id;
        }
        .rango{
            color: $fallowdata-color;
        }
        .puntos-YzbTb, .puntos{
            color: $itempublicacion_like_value_foreground_defauld;
        }
        .createAt{
            color: #317c9e;
            font-size: 12px;
        }
        .metas{
            border-top: 1px solid;
            border-color: $background_shadow_small_color;
        }
        button.siguiendo{
            background-color: #287eab;
            border-color: #5bc5ff;
        }
    }
}

$acento: hsl(177, 72%, 38%);
$acento_light: lighten($color: $acento, $amount: 35);
$acento_hover: darken($color: $acento, $amount: 5);
$acento_active: lighten($color: $acento, $amount: 5);

button.StandarButton{
    background: $acento;
    border: none;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 700;
    color: white;
    border-radius: 5px;
    transition: 0.3s;

    &.icon{
        padding-left: 45px;
        position: relative;
    }
    span.material-icons {
        font-size: 24px;
        position: absolute;
        left: 12px;
        top: 8px;
    }
    &:hover {
        background-color:$acento_hover;
        cursor: pointer;
    }
    &:active {
        background-color: $acento_active;
        cursor: pointer;
    }
    &.disabled {
        background-color: #7b818a;
        opacity: 0.5;
        cursor: inherit;
    }

}

.modal-dialog{
    .modal-content{
        background-color: $background_color_content;
        box-shadow: 0 0 20px 0px #000a15;
        border: 1px solid #06121f;
        .modal-header{
            border-bottom: 1px solid #06121f;
        }
        // .modal-body{
        // }
        @media screen and (max-width: 576px) {
            max-height: calc(100vh - 50px);
        }

        .modal-footer{
            border-top: 1px solid #06121f;
            .btn-secondary{
                box-shadow: none;
                background-color: #12253c;
                border-color: #123254;
                &:hover{
                    border-color: #123254;
                    background-color: lighten($color: #12253c, $amount: 3);
                }
                &:active{
                    background-color: lighten($color: #12253c, $amount: 5);
                }
            }
            .btn-primary {
                box-shadow: none !important;
                background-color: #1e3f50;
                border-color: #55c3ff;
                &:hover{
                    border-color: #55c3ff;
                    background-color: lighten($color: #1e3f50, $amount: 10);
                }
                &:active{
                    background-color: lighten($color: #1e3f50, $amount: 15);
                }
            }
        }
    }
    .modal-title{
        color: $text_color_titulo;
    }
    @include _FORM()
}

.btn-secondary.style{
    box-shadow: none;
    background-color: #12253c;
    border-color: #123254;
    &:hover{
        border-color: #123254;
        background-color: lighten($color: #12253c, $amount: 3);
    }
    &:active{
        background-color: lighten($color: #12253c, $amount: 5);
    }
    &:focus{
        background-color: #12253c;
        border-color: #123254;
    }
}
.btn-primary.style {
    box-shadow: none !important;
    background-color: #1e3f50;
    border-color: #22688e;
    &:hover{
        border-color: #22688e;
        background-color: lighten($color: #1e3f50, $amount: 5);
    }
    &:active{
        background-color: lighten($color: #1e3f50, $amount: 15);
    }
    &:focus{
        background-color: #1e3f50;
        border-color: #22688e;
    }
}


.create-shout-content{
    min-width: 250px;
    .toolbal{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0;
        padding: 0 4px;
        &.SelectOption{
            border: 1px solid $background_color_editor;
            padding: 5px;
            border-radius: 3px;
            margin-bottom: 10px;
            button.tox-tbtn{
                width: inherit;
                text-align: center;
                padding: 20px 15px !important;
                background: transparentize($color: #375a7f, $amount: 0.9);
                transition: 150ms;
                .text{
                    margin-top: 4px;
                    margin-left: 10px;
                }
            }
            .item_image{
                width: 100px;
                height: 100px;
            }
            .item_media{
                width: 160px;
                height: 120px;
            }
            .item_link{
                width: 100%;
                .link{
                    margin: 6px 0px;
                    margin-right: 35px;
                    a{
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                    }
                }
            }
            .button_close{
                position: relative;
                > button{
                    margin: 5px;
                    position: absolute;
                    z-index: 10;
                }
            }
            .preview_image{
                width: 100px;
                height: 100px;
                object-fit: contain;
                border-radius: 4px;
                background: $background_color_page;
            }
        }
    }
    textarea{
        min-height: 30px;
        //background: #FFF;
        border: none;
        font-family: "Lato","Helvetica Neue","Helvetica","Arial","Lucida Grande",sans-serif;
        border-radius: 3px;
        font-size: 14px;
        line-height: 150%;
        padding: 8px 10px;
        background: transparent;

        &:focus{
            //border: solid 1px $bar_backgraund;
            outline: none;
            background: transparent;
        }
        &:disabled{
            color: #495064;
        }
    }
    .tox-tbtn {
        align-items: center;
        background: 0 0;
        border: 0;
        border-radius: 3px;
        box-shadow: none;
        color: $text_color;
        display: flex;
        flex: 0 0 auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        height: 34px;
        justify-content: center;
        outline: 0;
        overflow: hidden;
        padding: 0;
        text-transform: normal;
        width: 34px;
        :not(svg) {
            color: inherit;
            cursor: inherit;
            font-size: inherit;
        }
        svg {
            display: block;
            fill: $text_color;
        }
        &:disabled{
            svg{
                fill: #495064;
            }
        }
        &:not(:disabled){
            &:hover {
                background: #375a7f;
                box-shadow: none;
                color: #fff;
                svg {
                    fill: #fff;
                }
            }
            &:active {
                background: darken($color: #375a7f, $amount: 10);
                svg {
                    fill: #fff;
                }
            }
        }
    }
}
.seguir_item{
    margin-right: 5px;
    margin-top: 5px;
    img{
        width: 40px;
        height: 40px;
        @include _PHOTO_SHADOW();
        border-radius: 5px;
        object-fit: cover;
    }
}

.panel_seguidores23{
    padding: 10px;
    padding-top: 8px;
    .list-unstyled{
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .isMobile &{
        padding: $mobile_padding_page;
        padding-left: $mobile_padding_page;
        padding-right: $mobile_padding_page;
    }
}
.font23{
    @include _FORM();
}


._mbmenu_{
    background: $background_color_content;
    > .row{
        box-shadow: 0 0 20px #0a0d10;
    }
    ._content{
        fill: $text_color_user;
        svg g{
            fill: inherit;
        }
        &:hover{
            fill: lighten($color:  $text_color_user, $amount: 10);
        }
    }
    .item{
        &.active{
            border-bottom: 2px solid $text_color_user;
        }
        &.create{
            // &:hover{
            //     border-bottom: 2px solid #ec5fa8;
            // }
            ._content{
                fill: #ec5fa8;
                &:hover{
                    fill: lighten($color:  #ec5fa8, $amount: 5);
                }
            }
        }
        .image_user{
          @include _PHOTO_SHADOW();
          border-radius: 5px;
        }
    }
    .popmenu{
        &-header{
            background: darken($color: $background_color_content, $amount: 3);
            button{
                background: transparentize($color: #375a7f, $amount: 0.9);
            }
        }
    }
}
.home-stats{
    .onlineusers{
        .fill{
            fill: lighten($color:  $acent_dark, $amount: 10);
        }
    }
    .col{
        color: $text_color_full;
        .fill{
            fill: lighten($color:  $acent_dark, $amount: 10);
        }
    }
}


span.onlineball{
    background: #2fff9f;
    border: 1px solid #38b453;
}
.apexcharts-tooltip{
    background: black !important;
    border-color: #0d1217 !important;
    .apexcharts-tooltip-title{
        background: black !important;
        border-color: #0d1217 !important;
    }
}
