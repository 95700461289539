img[emoticon] {
    vertical-align: text-bottom;
    width: 1.0em !important;
    //transform: translate(0px, -0.14em);
    border-radius: initial !important;
    margin: 0px;
    //image-rendering: pixelated;
    &{
        &[emoticon="crying"]{
            width: 2em !important; 
        }
        &[emoticon="alaba"]{
            width: 2.6em !important; 
        }
        &[emoticon="lpmqtp"]{
            width: 2em !important; 
        }
        &[emoticon="shrug"]{
            width: 2em !important; 
        }
        &[emoticon="8S"]{
            width: 1.04em !important; 
        }
        &[emoticon="]"]{
            width: 1.04em !important; 
        }
        &[emoticon="buaa"]{
            width: 1.7em !important; 
        }
        &[emoticon="idiot"]{
            width: 1.40em !important; 
        }
        &[emoticon="buenpost"]{
            width: 2.5em !important; 
        }
        &[emoticon="GET_A_LIFE"]{
            width: 2.7em !important; 
        }
        &[emoticon="headbang"]{
            width: 2em !important; 
        }
        &[emoticon="RIP"]{
            width: 1.6em !important; 
        }
        &[emoticon="LOL"]{ 
            width: 1.3em !important;
        }
        &[emoticon="globo"]{
            width: 1.4em !important; 
        }
        &[emoticon="blind"]{
            width: 1.2em !important; 
        }
        &[emoticon="OK"], &[emoticon="NO"], &[emoticon="exc"], &[emoticon="q"]{
            width: 1.5em !important; 
        }
    }
}
img[emoticon][editor]{
    width: 20px !important; 
    &:hover{
        //transform:  translate(0px,-2px);
    }
    &{
        &[emoticon="crying"]{
            width: 32px !important; 
        }
        &[emoticon="alaba"]{
            width: 52px !important; 
        }
        &[emoticon="lpmqtp"]{
            width: 32px !important; 
        }
        &[emoticon="shrug"]{
            width: 52px !important; 
        }
        &[emoticon="buaa"]{
            width: 32px !important; 
        }
        &[emoticon="buenpost"]{
            width: 26px !important; 
        }
        &[emoticon="GET_A_LIFE"]{
            width: 32px !important; 
        }
        &[emoticon="headbang"]{
            width: 32px !important; 
        }
        &[emoticon="RIP"]{
            width: 28px !important; 
        }
        &[emoticon="globo"]{
            width: 26px !important; 
        }
        &[emoticon="lala"]{
            width: 17px !important; 
        }
    }
} 
img[mce_icon]{
    width: 20px !important; 
    image-rendering: pixelated;
    padding: 3px 0px 0px 0px !important;
}
.mce_emoticones{
    max-width: 650px !important;
    .tox-collection__item-icon{
        img[emoticon]{

        }
        vertical-align: middle;
        width: 30px !important;
        height: 30px !important;
        overflow: hidden; 
    }
}