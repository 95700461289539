.page-user{


    >div > .content{
        @include _PANEL();
        .nav-tabs{
            border-bottom-color: #57a8ff33;
            .nav-link{
                color: $comment_text_boton_ytu;
            }
            .nav-link.active{
                background-color: $background_color_content;
                border-bottom-color: #57a8ff;
                color: $text_color;
            }
        }
    }
}

.userpanel{
    @include _PERFIL_DARK();
}