
    
    .page-notifications{
        .noti_container{
            .isMobile &{
                min-width: 100%;
            }
            .isTablet &, .isDesktop &{
                margin: 0px auto;
                width: initial !important;
                min-width:  initial !important;
            }
        }
    }
    .noti_header{
        min-width: 300px;
        width: 600px;
        max-width: 600px;
        margin: 0px auto;
        width: initial !important;
        min-width:  initial !important;
        margin-bottom: 20px;
        .title{
            font-size: 24px;
            border-bottom: 1px;
            span{
                font-size: 18px;
            }
        }
        color: #5b6d86 !important;
        .line{
            border-color: #5b6d86 !important;
        }
    }
    .noti_container.header-tooltip{
        min-width: 350px;
    }
    
    .noti_container{
        width: 30px;
        max-width: 400px;
        // max-height: 500px;
        // overflow: hidden;
        // overflow: overlay;
        font-family: inherit;
        font-size: 14px;
        font-weight: normal;
        .noti{
            white-space: normal;
            // margin: 5px 0px;
            // padding: 5px 5px;
            transition: background 1000ms;
            &._new_ {
                background: #6f1441af;
            }
            .header{
                // overflow: hidden;
                .photo{
                    border-radius: 4px;
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    object-fit: cover;
                    // .nano &{
                    //     width: 16px !important;
                    //     height: 16px !important;
                    // }
                }
                .media-body{
                    margin-top: -3px; 
                    margin-bottom: -3px;
                    overflow: hidden;
                }
                .time{
                    font-size: 13px;
                    color: #555;
                }
                &.nano{
                    .photo{
                        width: 18px;
                        height: 18px;
                        transform: translate(2px, 0px);
                    }
                }
            }
            .body{
                margin-top: .5rem;
                word-break: break-all;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 1;
            }
            .title{
                font-size: 13px;
                margin-top: -3px;
                display: block;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &.preview{
            flex-grow: 1;
            min-width: inherit !important;
            .time{
                display: none;
            }
            .noti {
                border-bottom: none !important;
                margin: 2px 0px 0px 0px !important;
                padding: 0 !important;
            }
            .header {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
.notistack-Snackbar > div{
    display: flex;
    flex-wrap: nowrap;
    a{
        overflow: hidden;
        word-wrap: break-word;
    }
}