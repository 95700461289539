
.MuiButtonBase-root{
    color: white !important;
}
@media screen and (max-width: 599.95px){
    .notistack-SnackbarContainer{
        align-items: center
    }
}
.notistack-Snackbar{
    min-width: inherit !important;
    > div{
        min-width: inherit !important;
        // background: #245194e6;
        // border: 1px solid #04111f;

        &.green {
            background: #1b772ae6;
            border: 1px solid #0d310c;
            color: #d8ffd7;
        }

        &.red {
            background: #8d032a;
            color: #ffd7d7;
        }

        &.purpure {
            background: #771b39e6;
            color: #ffd7d9;
        }
        &.orange {
            background: #774a1be6;
            color: #fffed7;
        }

        &.hden{
            opacity: 0;
        }
    }
    .noti_container{
        width: inherit !important;
        @media screen and (max-width: 768px){
            max-width: 70vw;
        }
        @media screen and (min-width: 769px){
            max-width: 275px;
        }
    }
}