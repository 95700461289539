.DfNcb{
    &-input{
        width: 200px;
        border: none;
        background-color: #151a27;
        padding: 9px 12px  9px 12px;
        color: rgba(255, 255, 255, 0.8);
        font-size: calc(14px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;
        transition: outline 150ms;
        outline: 1px solid transparent;
        &:focus{
            outline: 1px solid #283e5c;
        }
        &.noborde{
            // border-top-right-radius: 0;
            // border-bottom-right-radius: 0;
            padding-right: 45px;
        }
    }
    &-button{
        text-align: center;
        border: none;
        background: transparentize($color: #375a7f, $amount: 0.9);
        min-width: 36px;
    }
    &-button_close{
        margin-left: -36px;
    }
    &-button_add{
        margin-left: .5rem !important;
        border: 1px solid #0d141c;
        border-radius: 5px;
        transition: background 250ms;
        &:hover{
            background: lighten($color: #375a7f, $amount: 1);
        }
    }
    &-button_back{
        padding: 8px;
        border: 1px solid #0d141c;
        transition: background 250ms;
        &:hover{
            background: lighten($color: #375a7f, $amount: 1);
        }
    }
}