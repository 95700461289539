@mixin USER_CONTENT() {
    color: $text_color;
    h1, h2, h3, h4, h5, p, span, div{
        color: $text_color;
    }
    pre{
        color: $text_color_titulo;
    }

    div[style="background:#eeeeee;border:1px solid #cccccc;padding:5px 10px;"]{
        background-color: $box_background !important;
        border-color: $box_border !important;
    }

    .marker{
        background-color: $marker;
    }
}

@mixin _BUTTON($backgraund,$mul:1) { 
    background: $backgraund !important;
    &:hover{
        background: darken($backgraund, 5%*$mul) !important;
    }
    &:active{
        background: lighten($backgraund, 8%*$mul) !important;
    }
} 

.componen_message{
    text-align: center;
    .title{
        font-size: 15px;
        
    }
}

.componen_loading{
    text-align: center;
    .conteiner{
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }
      
}

.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  margin: 3px 0px -3px 0px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.dropdown-menu{
  border: 1px solid #0c151f !important;
}

@mixin _PERFIL(){
    .perfil{
        // padding-right: 0px;
        // .isMobile &{
        //     padding-left: 0px;
        //     padding-right: 0px;
        // }
        // .isNotMobile &{
        //     margin-bottom: 0px !important;
        // }
        .metas{
            
            @media only screen and (min-width: 768px) and (max-width: 980px)  {
                .hernibar{
                  width: 100% !important;
                }
            }
            @media only screen and (max-width: 370px)  {
                .hernibar{
                  width: 100% !important;
                }
            }
        }
        // .isMobile &{
        //     padding: $mobile_padding_page; 
        //     padding-left: $mobile_padding_page;
        //     padding-right: $mobile_padding_page;
        // }
        .photo-container{
          border-radius: 8px;
          overflow: hidden;
          .photo{
            background-size: cover;
            background-position: center;
            transition: transform 1000ms;
            &:hover{
              transform: scale(1.04);
            }
          }
          position: relative;
          #photo_de_perfil{
            width: 120px;
            height: 120px;
            object-fit: cover; 
          }
          .perfil-editar{
            cursor: pointer;
            position: absolute;
            bottom: 4px;
            left: 5px;
            font-size: 12px;
            padding: 5px;
            cursor: pointer;
            border: 1;
            padding: 3px 6px;
            display: flex;
            align-items: center;
            font-weight: bold;
            max-width: 24px;
            overflow: hidden;
            .text{
              color: transparent;
            } 
            &:hover{
              max-width: 99px;
              .text{
                transition: 100ms;
                color: inherit;
              } 
            }
          }
          span{
            // position: absolute;
            &.material-icons{
              // bottom: 4px;
              // left: 5px;
              font-size: 12px;
              padding-right: 3px;
              transform: translate(0px,-0px);
              // padding: 5px;
              // cursor: pointer;
              // border: 1;
            }
            &.onlineball{
              transform: translate(-5px,-0px);
            }
          }
          .lds-circle{
            >div{
              width: 10px;
              height: 10px;
              margin: 1px;
            }
          }
        }

        .name{
            font-size: 24px;
            line-height: 26px;
            font-weight: bold;
            //word-wrap: break-word; 
            overflow-wrap: anywhere;
            //word-break: break-all;
        }
        .username{
            font-size: 14px;
            line-height: 16px;
            font-style: italic;
        }

        .rango{
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
        }
        .puntos-YzbTb{
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
        }

        .metas{
            margin-top: 0.75rem;
            padding-top: 0.75rem;
            .value{
                font-size: 18px;
                font-weight: bold;
            }
        }

        &._nano{
          text-align: center;
          .photo-container{
            margin-top: 20px;
            .photo{
              width: 50vw !important;
              height: 50vw !important;
            }
            display: inline-block;
          }
        }
        &._medio{
          .perfil-linea{
            margin-top: 10px;
            margin-bottom: 13px;
          }
        }
        // .options_user{
        //   position: relative;
        //   .simple_tooltip {
        //     position: absolute;
        //     right: 10px;
        //     top: -28px;
        //     .isMobile &{
        //       .dropdown-menu{
        //         width: 250px;
        //         .dropdown-item{
        //           white-space: normal;
        //         }
        //       }
        //     }
        //   }
        // }
    }
}

.modal.show{
  display: block;
  z-index: 9999;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #fff;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

._mbmenu_{
  height: 48px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  .row{
    margin: 0px;
  }
  .item{
    height: 48px;
    width: 48px;
    position: relative;
    &:first-child{
      //background: red;
    }
    &:last-child{
      //background: red;
    }
    ._content{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .image_user{
        width: 32px;
        height: 32px;
        object-fit: cover;
        margin-top: -0px;
        transition: 300ms;
        &:active{
          width: 30px;
          height: 30px;
        }
      }
      .num{
        position: absolute;
        right: 0px;
        top: -5px;
        font-size: 10px;
        background: #6f1442;
        padding: 1px 4px 1px 4px;
        border-radius: 3px;
        transform: translate(0px, 0px) !important;
        display: inline-block !important;
        pointer-events: none;
      }
    }
    &.create{
      svg{
        transition: 300ms;
      }
      &:active{
        svg{
          width: 27px;
          height: 27px;
        }
      }
    }
  }
  .popmenu{
    min-height: 50px;
    position: absolute;
    bottom: 48px;
    background: #0d1217;
    left: 0px;
    right: 0px;
    /* text-shadow: 0 0 black; */
    box-shadow: 0 -20px 20px -20px black;
    &-content{
      max-height: 50vh;
      overflow: auto;
    }
    &-header{
      height: 48px;
      position: relative;
      button{
        position: absolute;
        right: 0px;
        top: 0px;
        text-align: center;
        margin-right: 10px;
        padding: 0px !important;
        svg{
          //margin: 10px 10px 10px 6px !important; 
        }
        .text{
            margin-top: 4px;
            margin-left: 10px;
        }
      }
      h5{
        padding: 15px;
        max-height: 37px;
        overflow: hidden;
        padding-right: 50px;
      }
    }
    
    button{
      border-bottom: 1px solid #0006;
      &.icon24{
          position: relative;
          // display: inline-block;
          // height: 30px;
          // width: 30px;
          // margin: -0.25rem 0px;
          padding: 0.6rem 1.5rem 0.6rem 1.5rem;
          padding-left: 45px;
      }
      .icon25{
          position: absolute;
          left: 24px;
          top: 50%;
          transform: translate(-50%, -50%);
      }
    }
  }
  .tox-tbtn {
    align-items: center;
    background: 0 0;
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    fill: #c5e1ff;
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 36px;
    justify-content: center;
    margin: 6px 0px;
    outline: 0;
    overflow: hidden;
    padding: 0;
    text-transform: normal;
    width: 36px;
}
}


.home-stats{
  margin-bottom: -5px;
  overflow: hidden;
  .stats-online{
      display: inline-block;
      font-size: 10px; 
      position: relative;
      vertical-align: middle;

      span{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
      }
  }
  
  .onlineusers{
    margin-right: 2.6px;
    margin-bottom: 3px;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    object-fit: cover;
    image-rendering: optimizeSpeed;
    .icon{
        display: none;
    }
    &:hover{
        .icon{
            display: initial;
        }
        .num{
          display: none !important;
        }
    }
    
  }
}
