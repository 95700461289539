.__shout__{
    .header{
        svg{
            fill: #7fc3ff;
        }
    }
    .photo{
        @include _PHOTO_SHADOW_OUTLINE();
    }
    .relevance{
        font-size: 10px;
        color: $comment_text_boton_ytu;
        padding: 10px;
    }
} 

.awssld__bullets{
    z-index: 10;
    button{
        width: 6px !important;
        height: 6px !important;
        //background: $text_color !important;
        //border: 1px solid $text_color_user !important;
        outline: none !important;
        &.awssld__bullets-active{
            width: 8px !important;
            height: 8px !important;
            transform: scale(1.2) !important;
            //background: #fff !important;
            //background: $text_color_user !important;
            //border: 1px solid $userlike_button_active !important;
            //@include _PHOTO_SHADOW();
        } 
    }
}
.awssld__wrapper{
    .awssld__controls{
        button{
            &:hover{
                background: #0066ff4d;
            }
        }
    }
}
$slider-transition-duration : 10ms;

.___dynbody___{
    background-color: #f5f5f5;
    .dark &{
        background-color: $background_color_backcontent; 
    }
    .media{
        &-link{
            &-a_photo{
                background: white;
            } 
        }
    }
}