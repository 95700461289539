
* { margin: 0; padding: 0; }

$xl: 87.5em; // 4k and hi-res desktop
$lg: 60em; // 1080 and hi-res laptops
$med: 40em; // hi-res tablets and low-res laptops
$sm: 28em; // mobile and low-res tablets
$xs: 20em; // mobile only

@mixin res($query) {
    @media ( min-width: + $query ) {
        @content;
    }
}
@mixin maxres($query) {
    @media ( max-width: + $query ) {
        @content;
    }
}

// .wrapper{
//   border: 1px solid black;
//   color: #000;
//   margin: 10px auto;
//   width: 90%;
// }

// header {
//   height: 5em;
//   width: 100%;
// }

// .container {
//   display: flex;
// }

// aside {
//   width: 30%;
// }

// section {
//   width: 70%;
// }

// footer {
//   height: 5em;
//   width: 100%;
// }

// .center {
//   text-align: center;
// }

/*line-height: 4em;
font-weight: bold;*/

.center_box{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.center_box2{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;
}

.center{
    text-align: center;
}


.linea {
    border: 1px solid #408af9;
    width: 100%;
    margin: 15px 0px;
}
.relative{
    position: relative;
}
// .relative > *{
//     position: absolute;
// }
.flex{
    display: flex;
}


.space{
    height: 15px;
}
.inli{
    display: inline-block;
}

.invi{
    position: absolute;
    opacity: 0;
}

.opa0{
    opacity: 0;
}
.mr0{
    margin-right: 0;
}

.mr10{
    margin-right: 10px;
}
.ml10{
    margin-left: 10px;
}
.mt10{
    margin-top: 10px;
}
.mb10{
    margin-bottom: 10px;
}
.mr15{
    margin-right: 15px;
}
.ml15{
    margin-left: 15px;
}
.mt15{
    margin-top: 15px;
}
.ml15{
    margin-bottom: 15px;
}

.mr10{
    margin-right: 10px;
}
.ml10{
    margin-left: 10px;
}
.mr15{
    margin-right: 15px;
}
.ml15{
    margin-left: 15px;
}

.right {
    float: right !important; 
}
.left {
    float: left !important;
} 

.full-width{
    width: 100%;
}