
html, body, #root{
    margin: 0px;
    padding: 0px;
}

html{
    height: 100%;
}

body{
    height: 100%;
}

#root{
    height: 100%;
    // display: flex;
    // flex-direction: column;
}

#content{
}
