.page-mensajes{
    
    ._mensaje_{
        white-space: normal;
        &._new_ .body{
            background: #6f1442;
        }
        .header{
            .photo{
                border-radius: 4px;
                width: 32px;
                height: 32px;
                margin-right: 10px;
                &.nano{
                    width: 20px;
                    height: 20px;
                    transform: translate(3px, -2px);
                }
            }
            .flecha{
                transform: translate(3px, 7px);
                color: #4d637d;
            }
            .media-body{
                margin-top: -10px;
                width: calc(100% - 42px);
            }
            .time{
                margin-top: 8px;
                font-size: 13px;
                color: #888;
            }
        }
        .body{
            margin-top: 3px;
            transition: 500ms;
            word-break: break-all;
        }
        .title{
            word-break: break-all;
            font-size: 13px;
            margin-top: -3px;
            display: block;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}