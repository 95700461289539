.layout-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.isMobile .layout-center-fixed{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.layout-center-text{
    text-align: center;
}