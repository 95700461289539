
$acent_dark: #2780E3;

$background_color_page : #121724;
$background_color_page : #151a27;
$background_color_content : #1a2436;
$background_color_content : #1d2333;

$background_color_content-hover: rgb(24, 39, 54);

$background_color_backcontent: #000; 
$background_shadow_color: #020d1a;
$background_shadow_content: 0 0 15px $background_shadow_color;
$background_shadow_small_color:#415468;
$background_shadow_small_content: 0 0 3px $background_shadow_small_color;

$border_color_container: rgb(29, 56, 83);
$border_color_container2: rgb(41, 137, 255);

$foreground_icon_color_editor: #669bbd; 
$background_color_editor: #0a0e17; 
$background_border_editor: 1px solid #0a1d27; 
$color_editor2: #40546171;
$text_editor: #c5e1ff; 

//abs
$ads_backgraund: rgb(24, 43, 61);

//Notificacion
$noti_bg_ok: rgba(3, 80, 57, 0.808);
$noti_br_ok: rgba(0, 255, 179, 0.808);
$noti_sw_ok:  0 0 13px #00000069;

//Text
$text_min: #aaa; 
$text_color: #c5e1ff; 
$text_color: #cfe6ff; 
$text_color_light: #8dbad3; 
$text_color_titulo: #71b5fd;
$text_color_url: #57a8ff;
$text_color_small: #71b5fd; 
$text_color_user: #00a2ff; 
$text_color_user_id: #e4cbe1;
$text_color_full: #00a2ff; 
$text_color_titulo-disabled: #25455a; 

//Content
$marker: #630000;
$box_background: #263b50;
$box_border: #7ea7d0;
$etiqueta_user: #eca5da;

$avatar_shadow-color: #0070d157;
$avatar_shadow: 0 0 15px 0px $avatar_shadow-color;
$avatar_border-color: #0070d1aa;
$avatar_border: 1px solid $avatar_border-color;  
$avatar_outline-color: #0070d171;
$avatar_outline: 1px solid $avatar_outline-color;  

//Bar
$bar_backgraund: #192734;
$bar_backgraund-hover: darken($bar_backgraund,4%);;
$bar_backgraund_contador: #2780E3;
$bar_backgraund_search: $background_color_content;;
$bar_border_search: 1px solid #0a1d27;
$acentcolor2: #1c5d92;

//Especial boton
$suscripto_backgraund: rgb(121, 14, 55);
$suscripto_foreground: rgb(255, 185, 203);
$suscripto_border: 1px solid rgb(44, 82, 121);
$suscribirse_backgraund: #ffffff05;
$suscribirse_foreground: rgb(134, 207, 255);
$suscribirse_border: 1px solid rgb(44, 82, 121);

//Post
$backgraund_puntos:rgba(104, 180, 255, 0.144);
$text_color_puntos: rgb(134, 207, 255); 
$text_color_puntostext_shadow-color: $border_color_container;
$text_color_puntostext_shadow: -1px 0 $border_color_container, 0 1px $border_color_container, 1px 0 $border_color_container, 0 -1px $border_color_container;
$text_color_puntostext_shadow_hover-color: lighten($color: $border_color_container, $amount: 20);
$text_color_puntostext_shadow_hover: -1px 0 $text_color_puntostext_shadow_hover-color , 0 1px $text_color_puntostext_shadow_hover-color , 1px 0 $text_color_puntostext_shadow_hover-color , 0 -1px $text_color_puntostext_shadow_hover-color ;
$fallowdata-color: rgb(180, 56, 87);
$success_border_button: rgb(50, 57, 153);
$etiquetas_post: #aadcff85;

$suscribirstate_backgraund: #ffffff05;
$suscribirstate_foreground: rgb(122, 176, 212);
$suscribirstated_border-color: rgb(44, 82, 121);

//Miniatura post
$miniaturapost_shadow: $background_shadow_content;
$miniaturapost_border: 0px solid rgba($text_color_titulo, 22);
$miniaturapost_image_border: 1px solid rgba($text_color_titulo, 33);
$miniaturapost_title_color: #57a8ff;
$miniaturapost_link_color: inherit;

//Usuario - Likes
$userlike_button_active: rgb(49, 124, 158);
$userlike_buttonicon_active: rgb(49, 124, 158);

//Comentarios
$comment_backgraund_boton: #5ca9c111;
$comment_icon_boton: #5ac3ff;
$comment_border_boton: $border_color_container;
$comment_text_boton: $text_color_titulo;
$comment_text_boton_ytu: #4a7c90; 
//$comment_like_foreground_boton: #8ffda7;
$comment_like_foreground_boton: #65ff86;
$comment_like_backgraund_boton_active: #226e40;
//$comment_like_foreground_boton_active: #a8ffcb;
$comment_like_foreground_boton_active: #80ffb3;
$comment_dislike_backgraund_boton_active: #ac2e2e;
$comment_dislike_foreground_boton_active: #ffdcdc;

$comment_shared_foreground_boton: #00eaff;
$comment_shared_backgraund_boton_active: #222d6e;
$comment_shared_foreground_boton_active: #00eaff;

$comment_delete_backgraund_boton: #5ca9c111;
$comment_delete_foreground_boton: #ff6161;
$comment_delete_border_boton: 1px solid $comment_delete_foreground_boton;

//Item-publicacion
$itempublicacion_shadow: $background_shadow_small_content;
$itempublicacion_clsSecondImg2: #005ac0b4;
$itempublicacion_clsSecondNuevo: #005ac0b4;
$itempublicacion_like_backgraund_defauld: #5ca9c111;
$itempublicacion_like_border_defauld: rgb(29, 56, 83);
$itempublicacion_like_value_foreground_defauld: #5ac3ff;
$itempublicacion_like_icon_backgraund_active: #226e40;
$itempublicacion_like_icon_foreground_active: #a8ffcb;
$itempublicacion_dislike_icon_backgraund_active: #ac2e2e;
$itempublicacion_dislike_icon_foreground_active: #ffdcdc;

$itempublicacion_comunidad_foreground: #ffdcdc;