
@import './dark/32_vals.scss';
//@import './dark/33_vals.scss';
//@import './dark/34_vals.scss';
//@import './dark/35_vals_embers.scss';
@import './dark/mixin.scss';
@import './dark/other.scss';
@import './dark/post.scss';
@import './dark/comments.scss';
@import './dark/user.scss';
@import './dark/header.scss';
@import './dark/notification.scss';
@import './dark/setting.scss';
@import './dark/register.scss';
@import './dark/home.scss';
@import './dark/editor.scss';
@import './dark/body.scss'; 
@import './dark/mensajes.scss'; 
@import './dark/random.scss'; 
@import './dark/login.scss'; 
@import './dark/shouts.scss';  
@import './dark/mi.scss';  


.center.message_bar {
    background: #6e1542;
    padding: 7px 5px 5px 5px;
}
.panel23{
    @include _PANEL();
    &.purpure{
        border-top: 1px solid;
        border-color: #ea6ead;
        background: #6e1542;
        border-radius: 5px;
        h5 span{
            color: #ffe9f4 !important;
        }
        a{
            color: #ffffff;
            font-weight: bold;
        }
    }
}

body{
    // background: #e5e5e5;
    background: #f4f2ee;
    color: #51595e !important; 
    html.dark &{
        background: $background_color_page;
        color: $text_color !important; 
    }
}
 
.message.error{
    color: $comment_dislike_backgraund_boton_active;
}
 
a{ 
    color: $acent_dark; 
    &:hover{
        color: lighten($color: $acent_dark, $amount: 20);
    }
}
 
._titlepage{
    color: #76b7ff !important;  
}

.pure-menu-selected>.pure-menu-link, .pure-menu-selected>.pure-menu-link:visited {
    color: $text_color_light;
}
.pure-menu-active>.pure-menu-link, .pure-menu-link:focus, .pure-menu-link:hover {
    @include _BUTTON($backgraund_puntos,3);      
} 
.pure-menu-children { 
    background-color: #183552;
}  
.pure-menu-link { 
    color: #fff;
}

.line{
    border-bottom-color: $background_shadow_small_color !important;
    border-bottom: 1px solid;
}


footer{
    span{
        color: $text_color_url;
    }
}



