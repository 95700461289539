.logo{
    &-normal{
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 24px;
        font-weight: bold;
        font-family: Play;
        color: #94ceff;
        // text-transform: uppercase;
        // display: block;
        position: relative;
        svg{
            // z-index: 25;
        }
    }
    &-event{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}