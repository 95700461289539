.page-setting{
    > .row{
        margin-left: 0px;
        margin-right: 0px;
        .isTablet &{
            margin-left: $desktop_margin_page;
            margin-right: $desktop_margin_page;
        }
        .isMobile &{
            margin-left: $mobile_margin_page;
            margin-right: $mobile_margin_page;
        }
    }
    .panel{
        padding-left: 10px;
        padding-right: 0px;
        @include _PERFIL();
        .isMobile &{
            padding-left: 0px;
            padding-right: 0px;
        }
        .isNotMobile &{
            margin-bottom: 0px !important;
        }
    }
    > div >.content{
        min-height: 100px;
        padding-top: 13px;
        .nav-link.active{
            border: 0px;
            border-bottom: 1px solid;
        }
        .menu_seguridad{
            margin-top: 15px;
        }
        .menu_cuenta{
            text-align: left;
            margin-top: 15px;
        }
    }
}