@mixin _Comments55() {
    .comments{
        .title{
            font-size: 24px;
            border-bottom: 1px;
            span{
                font-size: 18px;
            }
        }
        .light_message .message{
            font-size: 15px;
        }
    }
}

    .page-post{
        > div > .col{
            max-width: calc(100% - 33.3333333333%);
            padding-right: $desktop_margin_page;
            // padding-left: $desktop_margin_page;
            .isMobile &{
                padding-right: $mobile_margin_page;
                padding-left: $mobile_margin_page;
            }
        }
        
        article._post_{
            .___title___{
                .title{
                    overflow-wrap: anywhere;
                    max-width: 100%;
                    .isMobile &{
                        font-size: 27px;
                        margin-bottom: 1rem;
                    }
                }
            }
            .options{
                .category{
                    cursor: pointer;
                }
            }
        }

        .puntuo{
            margin: 0px 0px 20px 0px;
        }
        .puntear{
            :nth-child(11){
                margin: 0px 0px 0px 0px; 
            }
            .puntos{
                display: inline-block;
                padding-bottom: 0px;
                max-width: calc(100% - 100px); 
                .isMobile &{
                    max-width: 300px; 
                }
                @media only screen and (max-width: 400px)  {
                    max-width: 250px !important; 
                    ._2,._4,._6,._8{
                        display: none;
                    }
                }
                @media only screen and (max-width: 320px)  {
                    max-width: 200px !important; 
                } 
                > span{
                    padding: 8px 10px 9px 9px;
                    margin-right: 5px;
                    border-radius: 5px;
                    margin-bottom: 5px;
                    cursor: pointer; 
                    display: inline-block;
                    font-family: 'Lato';
                    font-weight: 700;
                    transition: 200ms background; 
                    user-select: none;
                    &:hover{
                        font-weight: 700;
                    }
                } 
            }
            
            div.right{
                font-size: 19px;
                span{
                    transform: translate(0px, 1px);
                    margin-right: 5px;
                }
            }
        }

        @include _PERFIL();
        @include _Comments55();

        .metas{
            @media only screen and (max-width: 1140px)  {
                .hernibar{
                  width: 100% !important;
                }
            }
        }
    }

    // .___body___{
    // }

    .user_perfil_mobile{
        .media-body{
            margin-top: -1px;
            width: calc(100% - 51px);
        }
        .photo{
            width: 41px;
            height: 41px;
            margin-right: 10px;
        }
        .name{
            margin-right: 5px;
            font-weight: bold;
            word-wrap: break-word;
            overflow-wrap: anywhere;
            word-break: break-all;
        }
        .puntos{
            font-size: 12px;
            transform: translate(0px, 2px);
        }
    }