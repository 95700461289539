/* @tailwind base; */
.container {
    width: 100%;
}
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
.visible {
    visibility: visible;
}
.collapse {
    visibility: collapse;
}
.static {
    position: static;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    inset: 0px;
}
.-top-0 {
    top: -0px;
}
.-top-0\.5 {
    top: -0.125rem;
}
.-top-\[0\.9rem\] {
    top: -0.9rem;
}
.bottom-0 {
    bottom: 0px;
}
.left-0 {
    left: 0px;
}
.left-1\/2 {
    left: 50%;
}
.left-3 {
    left: 0.75rem;
}
.left-4 {
    left: 1rem;
}
.left-5 {
    left: 1.25rem;
}
.left-6 {
    left: 1.5rem;
}
.left-\[\.85rem\] {
    left: .85rem;
}
.left-\[\.9rem\] {
    left: .9rem;
}
.left-\[10px\] {
    left: 10px;
}
.left-\[calc\(100px\+1rem\)\] {
    left: calc(100px + 1rem);
}
.left-\[calc\(40px\+\.5rem\)\] {
    left: calc(40px + .5rem);
}
.left-\[calc\(40px\+\.75rem\)\] {
    left: calc(40px + .75rem);
}
.left-\[calc\(40px\+0\.5rem\)\] {
    left: calc(40px + 0.5rem);
}
.left-\[calc\(50px\+\.5rem\)\] {
    left: calc(50px + .5rem);
}
.right-0 {
    right: 0px;
}
.right-1 {
    right: 0.25rem;
}
.right-8 {
    right: 2rem;
}
.right-\[-20px\] {
    right: -20px;
}
.right-\[-6px\] {
    right: -6px;
}
.top-0 {
    top: 0px;
}
.top-1\/2 {
    top: 50%;
}
.top-\[-60px\] {
    top: -60px;
}
.top-\[10px\] {
    top: 10px;
}
.top-\[14px\] {
    top: 14px;
}
.top-\[50\%\] {
    top: 50%;
}
.isolate {
    isolation: isolate;
}
.z-0 {
    z-index: 0;
}
.z-10 {
    z-index: 10;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.col-span-1 {
    grid-column: span 1 / span 1;
}
.col-span-2 {
    grid-column: span 2 / span 2;
}
.col-span-3 {
    grid-column: span 3 / span 3;
}
.col-span-4 {
    grid-column: span 4 / span 4;
}
.col-span-5 {
    grid-column: span 5 / span 5;
}
.col-span-7 {
    grid-column: span 7 / span 7;
}
.col-span-8 {
    grid-column: span 8 / span 8;
}
.float-left {
    float: left;
}
.\!m-0 {
    margin: 0px !important;
}
.m-0 {
    margin: 0px;
}
.m-1 {
    margin: 0.25rem;
}
.m-1\.5 {
    margin: 0.375rem;
}
.m-2 {
    margin: 0.5rem;
}
.m-3 {
    margin: 0.75rem;
}
.m-4 {
    margin: 1rem;
}
.-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.mx-0 {
    margin-left: 0px;
    margin-right: 0px;
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
}
.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.my-\[-25px\] {
    margin-top: -25px;
    margin-bottom: -25px;
}
.\!ml-0 {
    margin-left: 0px !important;
}
.\!mr-0 {
    margin-right: 0px !important;
}
.\!mr-2 {
    margin-right: 0.5rem !important;
}
.\!mr-\[60px\] {
    margin-right: 60px !important;
}
.\!mt-3 {
    margin-top: 0.75rem !important;
}
.-mt-1 {
    margin-top: -0.25rem;
}
.-mt-2 {
    margin-top: -0.5rem;
}
.-mt-4 {
    margin-top: -1rem;
}
.-mt-\[0\.3rem\] {
    margin-top: -0.3rem;
}
.-mt-\[0\.4rem\] {
    margin-top: -0.4rem;
}
.-mt-\[1px\] {
    margin-top: -1px;
}
.-mt-\[2px\] {
    margin-top: -2px;
}
.-mt-\[3px\] {
    margin-top: -3px;
}
.-mt-\[4px\] {
    margin-top: -4px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-1\.5 {
    margin-bottom: 0.375rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-3 {
    margin-bottom: 0.75rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-\[-100px\] {
    margin-bottom: -100px;
}
.mb-\[-1px\] {
    margin-bottom: -1px;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-3 {
    margin-left: 0.75rem;
}
.ml-4 {
    margin-left: 1rem;
}
.ml-6 {
    margin-left: 1.5rem;
}
.ml-\[10px\] {
    margin-left: 10px;
}
.ml-\[5px\] {
    margin-left: 5px;
}
.ml-\[calc\(1\.5rem\+16px\)\] {
    margin-left: calc(1.5rem + 16px);
}
.ml-\[calc\(120px\+\.75rem\)\] {
    margin-left: calc(120px + .75rem);
}
.ml-\[calc\(1rem\)\] {
    margin-left: calc(1rem);
}
.mr-0 {
    margin-right: 0px;
}
.mr-0\.5 {
    margin-right: 0.125rem;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-1\.5 {
    margin-right: 0.375rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-3 {
    margin-right: 0.75rem;
}
.mr-\[5px\] {
    margin-right: 5px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-0\.5 {
    margin-top: 0.125rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-12 {
    margin-top: 3rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mt-\[-0\.25em\] {
    margin-top: -0.25em;
}
.mt-\[\.25rem\] {
    margin-top: .25rem;
}
.mt-\[62px\] {
    margin-top: 62px;
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}
.line-clamp-6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.table {
    display: table;
}
.grid {
    display: grid;
}
.contents {
    display: contents;
}
.hidden {
    display: none;
}
.\!h-\[24px\] {
    height: 24px !important;
}
.\!h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
}
.h-1 {
    height: 0.25rem;
}
.h-10 {
    height: 2.5rem;
}
.h-12 {
    height: 3rem;
}
.h-16 {
    height: 4rem;
}
.h-2\/3 {
    height: 66.666667%;
}
.h-4 {
    height: 1rem;
}
.h-40 {
    height: 10rem;
}
.h-8 {
    height: 2rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[100px\] {
    height: 100px;
}
.h-\[11px\] {
    height: 11px;
}
.h-\[120px\] {
    height: 120px;
}
.h-\[12px\] {
    height: 12px;
}
.h-\[15px\] {
    height: 15px;
}
.h-\[16px\] {
    height: 16px;
}
.h-\[18px\] {
    height: 18px;
}
.h-\[1px\] {
    height: 1px;
}
.h-\[200px\] {
    height: 200px;
}
.h-\[20px\] {
    height: 20px;
}
.h-\[23px\] {
    height: 23px;
}
.h-\[24px\] {
    height: 24px;
}
.h-\[25px\] {
    height: 25px;
}
.h-\[28px\] {
    height: 28px;
}
.h-\[300px\] {
    height: 300px;
}
.h-\[30px\] {
    height: 30px;
}
.h-\[32px\] {
    height: 32px;
}
.h-\[36px\] {
    height: 36px;
}
.h-\[400px\] {
    height: 400px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[40vh\] {
    height: 40vh;
}
.h-\[42px\] {
    height: 42px;
}
.h-\[50px\] {
    height: 50px;
}
.h-\[600px\] {
    height: 600px;
}
.h-\[60vh\] {
    height: 60vh;
}
.h-\[67px\] {
    height: 67px;
}
.h-\[75px\] {
    height: 75px;
}
.h-\[calc\(100vh-100px\)\] {
    height: calc(100vh - 100px);
}
.h-\[calc\(32px\+0\.5rem\)\] {
    height: calc(32px + 0.5rem);
}
.h-\[calc\(32px\+1px\+1\.5rem\)\] {
    height: calc(32px + 1px + 1.5rem);
}
.h-\[calc\(48px\+1rem\)\] {
    height: calc(48px + 1rem);
}
.h-\[calc\(50px\+2rem\)\] {
    height: calc(50px + 2rem);
}
.h-\[calc\(64px\+1\.75rem\)\] {
    height: calc(64px + 1.75rem);
}
.h-\[calc\(64px\+1px\+2\.75rem\)\] {
    height: calc(64px + 1px + 2.75rem);
}
.h-fit {
    height: -moz-fit-content;
    height: fit-content;
}
.h-full {
    height: 100%;
}
.h-px {
    height: 1px;
}
.max-h-\[100vh\] {
    max-height: 100vh;
}
.min-h-\[120px\] {
    min-height: 120px;
}
.min-h-\[350px\] {
    min-height: 350px;
}
.min-h-\[36px\] {
    min-height: 36px;
}
.min-h-\[60vh\] {
    min-height: 60vh;
}
.min-h-\[calc\(100\%-48px\)\] {
    min-height: calc(100% - 48px);
}
.min-h-\[calc\(100\%-50px-2rem\)\] {
    min-height: calc(100% - 50px - 2rem);
}
.min-h-screen {
    min-height: 100vh;
}
.\!w-\[24px\] {
    width: 24px !important;
}
.\!w-auto {
    width: auto !important;
}
.\!w-full {
    width: 100% !important;
}
.w-1 {
    width: 0.25rem;
}
.w-10 {
    width: 2.5rem;
}
.w-\[100px\] {
    width: 100px;
}
.w-\[10px\] {
    width: 10px;
}
.w-\[120px\] {
    width: 120px;
}
.w-\[150px\] {
    width: 150px;
}
.w-\[24px\] {
    width: 24px;
}
.w-\[25px\] {
    width: 25px;
}
.w-\[28px\] {
    width: 28px;
}
.w-\[290px\] {
    width: 290px;
}
.w-\[32px\] {
    width: 32px;
}
.w-\[36px\] {
    width: 36px;
}
.w-\[40px\] {
    width: 40px;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[60px\] {
    width: 60px;
}
.w-\[75px\] {
    width: 75px;
}
.w-\[90px\] {
    width: 90px;
}
.w-\[calc\(100\%-1\.5rem\)\] {
    width: calc(100% - 1.5rem);
}
.w-full {
    width: 100%;
}
.w-max {
    width: -moz-max-content;
    width: max-content;
}
.w-screen {
    width: 100vw;
}
.min-w-0 {
    min-width: 0px;
}
.min-w-\[200px\] {
    min-width: 200px;
}
.min-w-\[40px\] {
    min-width: 40px;
}
.\!max-w-\[1000px\] {
    max-width: 1000px !important;
}
.max-w-7xl {
    max-width: 80rem;
}
.max-w-\[150px\] {
    max-width: 150px;
}
.max-w-\[20\%\] {
    max-width: 20%;
}
.max-w-\[250px\] {
    max-width: 250px;
}
.max-w-\[30\%\] {
    max-width: 30%;
}
.max-w-\[400px\] {
    max-width: 400px;
}
.max-w-\[460px\] {
    max-width: 460px;
}
.max-w-\[560px\] {
    max-width: 560px;
}
.max-w-\[66vw\] {
    max-width: 66vw;
}
.max-w-\[700px\] {
    max-width: 700px;
}
.max-w-\[90vw\] {
    max-width: 90vw;
}
.max-w-full {
    max-width: 100%;
}
.max-w-lg {
    max-width: 32rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-none {
    flex: none;
}
.flex-grow-\[1\] {
    flex-grow: 1;
}
.table-auto {
    table-layout: auto;
}
.border-collapse {
    border-collapse: collapse;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-\[1px\] {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-\[2px\] {
    --tw-translate-y: -2px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-3 {
    --tw-translate-x: 0.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1 {
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-1px\] {
    --tw-translate-y: -1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-2px\] {
    --tw-translate-y: -2px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-3px\] {
    --tw-translate-y: -3px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\] {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[1px\] {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[50px\] {
    --tw-translate-y: 50px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes pulse {
    50% {
        opacity: .5;
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-pointer {
    cursor: pointer;
}
.resize {
    resize: both;
}
.list-none {
    list-style-type: none;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.items-start {
    align-items: flex-start;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.gap-x-1\.5 {
    -moz-column-gap: 0.375rem;
         column-gap: 0.375rem;
}
.gap-x-3 {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}
.gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}
.gap-y-2 {
    row-gap: 0.5rem;
}
.gap-y-3 {
    row-gap: 0.75rem;
}
.gap-y-8 {
    row-gap: 2rem;
}
.\!space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.break-words {
    overflow-wrap: break-word;
}
.break-all {
    word-break: break-all;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-\[\.250rem\] {
    border-radius: .250rem;
}
.rounded-\[\.375rem\] {
    border-radius: .375rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-none {
    border-radius: 0px;
}
.rounded-sm {
    border-radius: 0.125rem;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-\[1px\] {
    border-width: 1px;
}
.border-\[2px\] {
    border-width: 2px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-\[1px\] {
    border-bottom-width: 1px;
}
.border-r-\[1px\] {
    border-right-width: 1px;
}
.border-solid {
    border-style: solid;
}
.border-none {
    border-style: none;
}
.border-\[\#00adff\] {
    --tw-border-opacity: 1;
    border-color: rgb(0 173 255 / var(--tw-border-opacity));
}
.border-\[\#0d111c\] {
    --tw-border-opacity: 1;
    border-color: rgb(13 17 28 / var(--tw-border-opacity));
}
.border-\[\#57a8ff30\] {
    border-color: #57a8ff30;
}
.border-\[\#57a8ff33\] {
    border-color: #57a8ff33;
}
.border-\[\#e1e8ed\] {
    --tw-border-opacity: 1;
    border-color: rgb(225 232 237 / var(--tw-border-opacity));
}
.border-black\/40 {
    border-color: rgb(0 0 0 / 0.4);
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.border-inherit {
    border-color: inherit;
}
.border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249 / var(--tw-border-opacity));
}
.border-transparent {
    border-color: transparent;
}
.border-b-\[\#e1e8ed\] {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(225 232 237 / var(--tw-border-opacity));
}
.\!bg-green-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#0a0e17\] {
    --tw-bg-opacity: 1;
    background-color: rgb(10 14 23 / var(--tw-bg-opacity));
}
.bg-\[\#11151f\] {
    --tw-bg-opacity: 1;
    background-color: rgb(17 21 31 / var(--tw-bg-opacity));
}
.bg-\[\#111827\] {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.bg-\[\#151a27\] {
    --tw-bg-opacity: 1;
    background-color: rgb(21 26 39 / var(--tw-bg-opacity));
}
.bg-\[\#181e2e\] {
    --tw-bg-opacity: 1;
    background-color: rgb(24 30 46 / var(--tw-bg-opacity));
}
.bg-\[\#1d2333\] {
    --tw-bg-opacity: 1;
    background-color: rgb(29 35 51 / var(--tw-bg-opacity));
}
.bg-\[\#313d4a\]\/30 {
    background-color: rgb(49 61 74 / 0.3);
}
.bg-\[\#313d4a\]\/50 {
    background-color: rgb(49 61 74 / 0.5);
}
.bg-\[\#3f6291aa\] {
    background-color: #3f6291aa;
}
.bg-\[\#FFF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-\[\#b01e69\] {
    --tw-bg-opacity: 1;
    background-color: rgb(176 30 105 / var(--tw-bg-opacity));
}
.bg-\[\#e1e8ed\] {
    --tw-bg-opacity: 1;
    background-color: rgb(225 232 237 / var(--tw-bg-opacity));
}
.bg-\[\#edf3f8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(237 243 248 / var(--tw-bg-opacity));
}
.bg-\[\#ffffff91\] {
    background-color: #ffffff91;
}
.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-black\/20 {
    background-color: rgb(0 0 0 / 0.2);
}
.bg-black\/30 {
    background-color: rgb(0 0 0 / 0.3);
}
.bg-black\/75 {
    background-color: rgb(0 0 0 / 0.75);
}
.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bg-blue-500\/80 {
    background-color: rgb(59 130 246 / 0.8);
}
.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-cyan-500\/10 {
    background-color: rgb(6 182 212 / 0.1);
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.bg-gray-500\/80 {
    background-color: rgb(107 114 128 / 0.8);
}
.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.bg-gray-700\/80 {
    background-color: rgb(55 65 81 / 0.8);
}
.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
.bg-green-500\/10 {
    background-color: rgb(34 197 94 / 0.1);
}
.bg-green-500\/80 {
    background-color: rgb(34 197 94 / 0.8);
}
.bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}
.bg-indigo-500\/80 {
    background-color: rgb(99 102 241 / 0.8);
}
.bg-orange-500\/80 {
    background-color: rgb(249 115 22 / 0.8);
}
.bg-purple-500\/80 {
    background-color: rgb(168 85 247 / 0.8);
}
.bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.bg-red-500\/10 {
    background-color: rgb(239 68 68 / 0.1);
}
.bg-red-500\/80 {
    background-color: rgb(239 68 68 / 0.8);
}
.bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}
.bg-rose-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}
.bg-sky-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}
.bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.bg-teal-500\/80 {
    background-color: rgb(20 184 166 / 0.8);
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow-500\/10 {
    background-color: rgb(234 179 8 / 0.1);
}
.bg-yellow-500\/80 {
    background-color: rgb(234 179 8 / 0.8);
}
.bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}
.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.from-gray-600 {
    --tw-gradient-from: #4b5563 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.bg-cover {
    background-size: cover;
}
.bg-clip-border {
    background-clip: border-box;
}
.fill-\[\#57a8ff\] {
    fill: #57a8ff;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.p-0 {
    padding: 0px;
}
.p-0\.5 {
    padding: 0.125rem;
}
.p-1 {
    padding: 0.25rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-6 {
    padding: 1.5rem;
}
.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-\[\.625rem\] {
    padding-left: .625rem;
    padding-right: .625rem;
}
.px-\[\.875rem\] {
    padding-left: .875rem;
    padding-right: .875rem;
}
.px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
}
.px-mobile {
    padding-left: 10px;
    padding-right: 10px;
}
.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-\[\.375rem\] {
    padding-top: .375rem;
    padding-bottom: .375rem;
}
.py-\[\.625rem\] {
    padding-top: .625rem;
    padding-bottom: .625rem;
}
.pb-0 {
    padding-bottom: 0px;
}
.pb-0\.5 {
    padding-bottom: 0.125rem;
}
.pb-1 {
    padding-bottom: 0.25rem;
}
.pb-1\.5 {
    padding-bottom: 0.375rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-\[2px\] {
    padding-bottom: 2px;
}
.pl-1 {
    padding-left: 0.25rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-\[30px\] {
    padding-left: 30px;
}
.pl-\[3px\] {
    padding-left: 3px;
}
.pl-\[calc\(12px\+0\.75rem\)\] {
    padding-left: calc(12px + 0.75rem);
}
.pl-\[calc\(14px\+0\.75rem\)\] {
    padding-left: calc(14px + 0.75rem);
}
.pl-\[calc\(16px\+0\.75rem\)\] {
    padding-left: calc(16px + 0.75rem);
}
.pl-\[calc\(16px\+1rem\)\] {
    padding-left: calc(16px + 1rem);
}
.pl-\[calc\(18px\+1\.25rem\)\] {
    padding-left: calc(18px + 1.25rem);
}
.pl-\[calc\(40px\+\.75rem\)\] {
    padding-left: calc(40px + .75rem);
}
.pl-\[calc\(50px\+\.75rem\)\] {
    padding-left: calc(50px + .75rem);
}
.pr-\[9px\] {
    padding-right: 9px;
}
.pt-0 {
    padding-top: 0px;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-\[0\.5em\] {
    padding-top: 0.5em;
}
.pt-\[1px\] {
    padding-top: 1px;
}
.pt-\[2px\] {
    padding-top: 2px;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.align-baseline {
    vertical-align: baseline;
}
.align-top {
    vertical-align: top;
}
.align-middle {
    vertical-align: middle;
}
.align-bottom {
    vertical-align: bottom;
}
.font-\[Roboto\] {
    font-family: Roboto;
}
.font-\[sans-serif\] {
    font-family: sans-serif;
}
.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.font-sans {
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.\!text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-\[0\.997rem\] {
    font-size: 0.997rem;
}
.text-\[1\.2rem\] {
    font-size: 1.2rem;
}
.text-\[1\.446rem\] {
    font-size: 1.446rem;
}
.text-\[1\.5rem\] {
    font-size: 1.5rem;
}
.text-\[1\.65rem\] {
    font-size: 1.65rem;
}
.text-\[1\.796rem\] {
    font-size: 1.796rem;
}
.text-\[10px\] {
    font-size: 10px;
}
.text-\[11px\] {
    font-size: 11px;
}
.text-\[12px\] {
    font-size: 12px;
}
.text-\[14px\] {
    font-size: 14px;
}
.text-\[16px\] {
    font-size: 16px;
}
.text-\[18px\] {
    font-size: 18px;
}
.text-\[200px\] {
    font-size: 200px;
}
.text-\[20px\] {
    font-size: 20px;
}
.text-\[2rem\] {
    font-size: 2rem;
}
.text-\[30px\] {
    font-size: 30px;
}
.text-\[6rem\] {
    font-size: 6rem;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-\[500\] {
    font-weight: 500;
}
.font-\[700\] {
    font-weight: 700;
}
.font-bold {
    font-weight: 700;
}
.font-light {
    font-weight: 300;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 600;
}
.italic {
    font-style: italic;
}
.leading-4 {
    line-height: 1rem;
}
.leading-5 {
    line-height: 1.25rem;
}
.leading-\[0px\] {
    line-height: 0px;
}
.leading-\[1\] {
    line-height: 1;
}
.leading-none {
    line-height: 1;
}
.leading-normal {
    line-height: 1.5;
}
.\!text-blue-200 {
    --tw-text-opacity: 1 !important;
    color: rgb(191 219 254 / var(--tw-text-opacity)) !important;
}
.\!text-gray-500 {
    --tw-text-opacity: 1 !important;
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}
.\!text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-\[\#00a2ff\] {
    --tw-text-opacity: 1;
    color: rgb(0 162 255 / var(--tw-text-opacity));
}
.text-\[\#00adff\] {
    --tw-text-opacity: 1;
    color: rgb(0 173 255 / var(--tw-text-opacity));
}
.text-\[\#3e71b5\] {
    --tw-text-opacity: 1;
    color: rgb(62 113 181 / var(--tw-text-opacity));
}
.text-\[\#444852\] {
    --tw-text-opacity: 1;
    color: rgb(68 72 82 / var(--tw-text-opacity));
}
.text-\[\#4c7996\] {
    --tw-text-opacity: 1;
    color: rgb(76 121 150 / var(--tw-text-opacity));
}
.text-\[\#51595e\] {
    --tw-text-opacity: 1;
    color: rgb(81 89 94 / var(--tw-text-opacity));
}
.text-\[\#57a8ff\] {
    --tw-text-opacity: 1;
    color: rgb(87 168 255 / var(--tw-text-opacity));
}
.text-\[\#5d6783\] {
    --tw-text-opacity: 1;
    color: rgb(93 103 131 / var(--tw-text-opacity));
}
.text-\[\#89335a\] {
    --tw-text-opacity: 1;
    color: rgb(137 51 90 / var(--tw-text-opacity));
}
.text-\[\#b43857\] {
    --tw-text-opacity: 1;
    color: rgb(180 56 87 / var(--tw-text-opacity));
}
.text-\[\#daedff\] {
    --tw-text-opacity: 1;
    color: rgb(218 237 255 / var(--tw-text-opacity));
}
.text-\[\#ff0d72\] {
    --tw-text-opacity: 1;
    color: rgb(255 13 114 / var(--tw-text-opacity));
}
.text-blue-200 {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity));
}
.text-blue-300 {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
}
.text-blue-300\/50 {
    color: rgb(147 197 253 / 0.5);
}
.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
}
.text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.text-cyan-500 {
    --tw-text-opacity: 1;
    color: rgb(6 182 212 / var(--tw-text-opacity));
}
.text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
.text-inherit {
    color: inherit;
}
.text-red-200 {
    --tw-text-opacity: 1;
    color: rgb(254 202 202 / var(--tw-text-opacity));
}
.text-red-300 {
    --tw-text-opacity: 1;
    color: rgb(252 165 165 / var(--tw-text-opacity));
}
.text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
}
.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-500\/70 {
    color: rgb(239 68 68 / 0.7);
}
.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}
.text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity));
}
.underline {
    text-decoration-line: underline;
}
.opacity-0 {
    opacity: 0;
}
.opacity-10 {
    opacity: 0.1;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-80 {
    opacity: 0.8;
}
.opacity-90 {
    opacity: 0.9;
}
.opacity-\[5\%\] {
    opacity: 5%;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline {
    outline-style: solid;
}
.outline-dashed {
    outline-style: dashed;
}
.outline-2 {
    outline-width: 2px;
}
.outline-blue-300\/50 {
    outline-color: rgb(147 197 253 / 0.5);
}
.outline-gray-200 {
    outline-color: #e5e7eb;
}
.outline-gray-300 {
    outline-color: #d1d5db;
}
.outline-gray-300\/10 {
    outline-color: rgb(209 213 219 / 0.1);
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-none {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-none {
    transition-property: none;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.delay-150 {
    transition-delay: 150ms;
}
.duration-150 {
    transition-duration: 150ms;
}
.duration-300 {
    transition-duration: 300ms;
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.minimal-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.minimal-scrollbar::-webkit-scrollbar-track {
    background: #1d2333;
}
.minimal-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}
.minimal-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.minimal-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #1d2333;
}
.\[my\:delete\] {
    my: delete;
}
.\[other\:delete\] {
    other: delete;
}


/* Esto lo añadi para que transform-translate de tailwild funcione */
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
  }
.hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(96 165 250 / var(--tw-border-opacity));
}
.hover\:bg-\[\#00000011\]:hover {
    background-color: #00000011;
}
.hover\:bg-\[\#151a2722\]:hover {
    background-color: #151a2722;
}
.hover\:bg-\[\#313d4a\]\/30:hover {
    background-color: rgb(49 61 74 / 0.3);
}
.hover\:bg-\[\#c9267b\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(201 38 123 / var(--tw-bg-opacity));
}
.hover\:bg-black\/20:hover {
    background-color: rgb(0 0 0 / 0.2);
}
.hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}
.hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.hover\:bg-slate-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.hover\:\!text-white:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:outline:hover {
    outline-style: solid;
}
.hover\:outline-blue-300\/50:hover {
    outline-color: rgb(147 197 253 / 0.5);
}
.hover\:blur-none:hover {
    --tw-blur: blur(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:duration-100:hover {
    transition-duration: 100ms;
}
.focus\:border-\[\#94bbe2\]:focus {
    --tw-border-opacity: 1;
    border-color: rgb(148 187 226 / var(--tw-border-opacity));
}
.focus\:shadow-shadowForm:focus {
    --tw-shadow: 0 0 0 0.2rem rgba(55,90,127,0.25);
    --tw-shadow-colored: 0 0 0 0.2rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:outline-0:focus {
    outline-width: 0px;
}
.focus\:outline-gray-300:focus {
    outline-color: #d1d5db;
}
.active\:scale-95:active {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.active\:bg-\[\#00000022\]:active {
    background-color: #00000022;
}
.active\:bg-\[\#bf4a87\]:active {
    --tw-bg-opacity: 1;
    background-color: rgb(191 74 135 / var(--tw-bg-opacity));
}
.active\:bg-blue-100:active {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.active\:bg-blue-300:active {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.active\:bg-blue-400:active {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}
.active\:bg-blue-600:active {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.active\:bg-slate-700:active {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}
.active\:duration-0:active {
    transition-duration: 0s;
}
:is(.dark .dark\:border-none) {
    border-style: none;
}
:is(.dark .dark\:border-\[\#06121f\]) {
    --tw-border-opacity: 1;
    border-color: rgb(6 18 31 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-\[\#0a0e17\]) {
    --tw-border-opacity: 1;
    border-color: rgb(10 14 23 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-\[\#415468\]) {
    --tw-border-opacity: 1;
    border-color: rgb(65 84 104 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-\[\#57a8ff\]) {
    --tw-border-opacity: 1;
    border-color: rgb(87 168 255 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-slate-600) {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-slate-700) {
    --tw-border-opacity: 1;
    border-color: rgb(51 65 85 / var(--tw-border-opacity));
}
:is(.dark .dark\:border-b-\[\#06121f\]) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(6 18 31 / var(--tw-border-opacity));
}
:is(.dark .dark\:bg-\[\#0a0e17\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(10 14 23 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#0f171f\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 31 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#136dcd\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(19 109 205 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#151a27\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 26 39 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#182e3a\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(24 46 58 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#1d2333\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(29 35 51 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#1d3853\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(29 56 83 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#283e5c\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(40 62 92 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#2a3248\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(42 50 72 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#375a7f1a\]) {
    background-color: #375a7f1a;
}
:is(.dark .dark\:bg-\[\#6f1342\]) {
    --tw-bg-opacity: 1;
    background-color: rgb(111 19 66 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-\[\#ffffff11\]) {
    background-color: #ffffff11;
}
:is(.dark .dark\:bg-\[\#ffffff1a\]) {
    background-color: #ffffff1a;
}
:is(.dark .dark\:bg-black) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-gray-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-gray-500\/75) {
    background-color: rgb(107 114 128 / 0.75);
}
:is(.dark .dark\:bg-gray-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-slate-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
:is(.dark .dark\:from-\[\#000C\]) {
    --tw-gradient-from: #000C var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
:is(.dark .dark\:from-\[\#171d2d\]) {
    --tw-gradient-from: #171d2d var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(23 29 45 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
:is(.dark .dark\:to-\[\#171d2dc2\]) {
    --tw-gradient-to: #171d2dc2 var(--tw-gradient-to-position);
}
:is(.dark .dark\:text-\[\#5b6d86\]) {
    --tw-text-opacity: 1;
    color: rgb(91 109 134 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[\#77b8fd\]) {
    --tw-text-opacity: 1;
    color: rgb(119 184 253 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[\#94ceff\]) {
    --tw-text-opacity: 1;
    color: rgb(148 206 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[\#cfe6ff\]) {
    --tw-text-opacity: 1;
    color: rgb(207 230 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[\#daedff\]) {
    --tw-text-opacity: 1;
    color: rgb(218 237 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[\#fff4fb\]) {
    --tw-text-opacity: 1;
    color: rgb(255 244 251 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-\[rgba\(165\2c 208\2c 255\2c 0\.89\)\]) {
    color: rgba(165,208,255,0.89);
}
:is(.dark .dark\:text-blue-200) {
    --tw-text-opacity: 1;
    color: rgb(191 219 254 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-blue-500) {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-gray-200) {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-gray-300) {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-gray-400) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-inherit) {
    color: inherit;
}
:is(.dark .dark\:text-slate-200) {
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-slate-400) {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-white) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:shadow-none) {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark .dark\:shadow-shadowClassic) {
    --tw-shadow: 0 0 15px 0px rgba(0, 112, 209, 0.3411764706);
    --tw-shadow-colored: 0 0 15px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark .dark\:shadow-shadowClassicRed) {
    --tw-shadow: 0 0 15px 0px #ff006a50;
    --tw-shadow-colored: 0 0 15px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark .dark\:outline-\[\#283e5c\]) {
    outline-color: #283e5c;
}
:is(.dark .dark\:outline-\[\#ff006a50\]) {
    outline-color: #ff006a50;
}
:is(.dark .dark\:outline-blue-500\/50) {
    outline-color: rgb(59 130 246 / 0.5);
}
:is(.dark .dark\:outline-gray-500\/30) {
    outline-color: rgb(107 114 128 / 0.3);
}
:is(.dark .dark\:outline-gray-800) {
    outline-color: #1f2937;
}
:is(.dark .dark\:hover\:border-\[\#2780E3\]:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(39 128 227 / var(--tw-border-opacity));
}
:is(.dark .dark\:hover\:bg-\[\#0f171f7b\]:hover) {
    background-color: #0f171f7b;
}
:is(.dark .dark\:hover\:bg-\[\#151a27\]:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 26 39 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-\[\#1e3e50\]:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 62 80 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-\[\#428ad7\]:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(66 138 215 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-\[\#9b1a5c\]:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(155 26 92 / var(--tw-bg-opacity));
}
:is(.dark .dark\:hover\:bg-\[\#ffffff33\]:hover) {
    background-color: #ffffff33;
}
:is(.dark .dark\:hover\:text-\[\#FFF\]:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:hover\:text-white:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:hover\:outline:hover) {
    outline-style: solid;
}
:is(.dark .dark\:hover\:outline-blue-500\/50:hover) {
    outline-color: rgb(59 130 246 / 0.5);
}
:is(.dark .dark\:focus\:border-\[\#739ac2\]:focus) {
    --tw-border-opacity: 1;
    border-color: rgb(115 154 194 / var(--tw-border-opacity));
}
:is(.dark .dark\:focus\:bg-\[\#121929\]:focus) {
    --tw-bg-opacity: 1;
    background-color: rgb(18 25 41 / var(--tw-bg-opacity));
}
:is(.dark .dark\:focus\:outline-\[\#283e5c\]:focus) {
    outline-color: #283e5c;
}
:is(.dark .dark\:active\:bg-\[\#16222e7b\]:active) {
    background-color: #16222e7b;
}
:is(.dark .dark\:active\:bg-\[\#1e2e3f\]:active) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 46 63 / var(--tw-bg-opacity));
}
:is(.dark .dark\:active\:bg-\[\#ffffff44\]:active) {
    background-color: #ffffff44;
}
:is(.dark .dark\:active\:hover\:bg-\[\#3f93ed\]:hover:active) {
    --tw-bg-opacity: 1;
    background-color: rgb(63 147 237 / var(--tw-bg-opacity));
}
:is(.dark .dark\:active\:hover\:bg-\[\#b01e69\]:hover:active) {
    --tw-bg-opacity: 1;
    background-color: rgb(176 30 105 / var(--tw-bg-opacity));
}
:is(.dark .dark\:disabled\:cursor-auto:disabled) {
    cursor: auto;
}
:is(.dark .dark\:disabled\:border-\[\#282d3c\]:disabled) {
    --tw-border-opacity: 1;
    border-color: rgb(40 45 60 / var(--tw-border-opacity));
}
:is(.dark .dark\:disabled\:bg-\[\#8888881a\]:disabled) {
    background-color: #8888881a;
}
:is(.dark .dark\:disabled\:bg-\[\#a7246844\]:disabled) {
    background-color: #a7246844;
}
:is(.dark .dark\:disabled\:text-\[\#37465f\]:disabled) {
    --tw-text-opacity: 1;
    color: rgb(55 70 95 / var(--tw-text-opacity));
}
:is(.dark .dark\:disabled\:text-gray-400:disabled) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
:is(.dark .dark\:disabled\:opacity-75:disabled) {
    opacity: 0.75;
}
:is(.dark .dark\:disabled\:hover\:bg-\[\#8888881a\]:hover:disabled) {
    background-color: #8888881a;
}
:is(.dark .dark\:disabled\:hover\:bg-\[\#a7246844\]:hover:disabled) {
    background-color: #a7246844;
}
@media (min-width: 640px) {
    .sm\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .sm\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .sm\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .sm\:mb-5 {
        margin-bottom: 1.25rem;
    }
    .sm\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .sm\:rounded-md {
        border-radius: 0.375rem;
    }
    .sm\:p-4 {
        padding: 1rem;
    }
    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
@media (min-width: 768px) {
    .md\:order-1 {
        order: 1;
    }
    .md\:order-first {
        order: -9999;
    }
    .md\:-mt-4 {
        margin-top: -1rem;
    }
    .md\:-mt-6 {
        margin-top: -1.5rem;
    }
    .md\:mb-0 {
        margin-bottom: 0px;
    }
    .md\:mt-0 {
        margin-top: 0px;
    }
    .md\:grid {
        display: grid;
    }
    .md\:h-\[calc\(100vh-200px\)\] {
        height: calc(100vh - 200px);
    }
    .md\:w-full {
        width: 100%;
    }
    .md\:-translate-y-0 {
        --tw-translate-y: -0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:-translate-y-0\.5 {
        --tw-translate-y: -0.125rem;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .md\:gap-4 {
        gap: 1rem;
    }
    .md\:gap-8 {
        gap: 2rem;
    }
    .md\:p-0 {
        padding: 0px;
    }
    .md\:p-5 {
        padding: 1.25rem;
    }
    .md\:p-6 {
        padding: 1.5rem;
    }
    .md\:py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .md\:py-1\.5 {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }
    .md\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    .md\:text-\[10rem\] {
        font-size: 10rem;
    }
    .md\:text-\[2rem\] {
        font-size: 2rem;
    }
    .md\:active\:scale-\[98\%\]:active {
        --tw-scale-x: 98%;
        --tw-scale-y: 98%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
}
@media (min-width: 1024px) {
    .lg\:my-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .lg\:max-w-\[800px\] {
        max-width: 800px;
    }
    .lg\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:items-start {
        align-items: flex-start;
    }
    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}