
// ⚠️ Ver que no lo pise el modal de mensaje privados
// Modal de emoticones de tinymce
.tox.tox-silver-sink.tox-tinymce-aux{
    z-index: 9999;
}


// ⚠️ Si el header destop tiene mas de 0, el .dropdown-menu sera pisado siempre
// header desktop = 1100 

// .modal = 1000

// ⚠️ Este tiene dos valores
// .userpanel .perfil .options_user .simple_tooltip
// .dropdown-menu -> tooltip-menu-desktop = 1202