.logo-event-trabajador{
    &-bandera_ar{
        position: absolute;
        left: -28px;
        top: 0px;
        height: 10px;
        object-fit: cover;
        transform: rotate(25deg);
    }
    &-escarapela{
        position: absolute;
        left: -21px;
        top: -6px;
        height: 28px;
        object-fit: cover;
        transform: rotate(356deg);
    }
    &-empanada{
        position: absolute;
        left: -30px;
        bottom: -7px;
        height: 18px;
        object-fit: cover;
        opacity: 30;
        transform: rotate(350deg);
    }
    &-mate{
        position: absolute;
        left: 116px;
        bottom: -4px;
        height: 26px;
        object-fit: cover;
        opacity: 30;
        transform: rotate(11deg);
    }
}