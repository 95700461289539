.rLmtSQKDjm{
    &-container {
        background-color: #1d2333;
        // height: 50px;
        // box-shadow: 1;
        // position: fixed;
        // top: 0;
        // left: 0;
        // right: 0;
        // z-index: 999;
        // transition: transform 100ms;
    }
    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    &-groud {
        height: 50px;
        display: flex;
        align-items: center;
    }
}