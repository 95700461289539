@import '../../../../../scss/dark/mixin';

.y2buQ{
    position: relative;
    transform: translate(0,1px);
    &-input{
        width: 200px;
        border: none;
        // background-color: #151a27;
        padding: 8px 35px  8px 14px;
        color: rgba(255, 255, 255, 0.8);
        font-size: calc(14px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;
        transition: outline 150ms;
        outline: 1px solid transparent;
        &:focus{
            outline: 1px solid #283e5c;
        }
        .y2buQ.large &{
            width: 350px;
        }
    }
    
    &-iconSearch{
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        // transition: background 250ms;
        user-select: none;
        .y2buQ.large &{
            width: 38px;
        }
        .y2buQ.text &{
            cursor: pointer;
            background: #283e5c;
            &:hover{
                background: lighten($color: #283e5c, $amount: 3);
            }
            &:active{
                background: lighten($color: #283e5c, $amount: 5);
            }
        }
    }
    &-iconClose{
        padding-bottom: 2px;
        position: absolute;
        right: 36px;
        top: 0;
        bottom: 0;
        transition: opacity 150ms;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: #151a27;
        cursor: pointer;
        &.hide{
            opacity: 0;
            display: none;
        }
        svg{
            background: transparent;
        }
        // &:hover{
        //     background: #30233e;
        // }
        // &:active{
        //     background: #382947;
        // }

    }
}