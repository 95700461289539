._comment_{
    &._my_{
        span.name {
            color: #ff0d72 !important;
            &:hover{
                color: lighten($color: #ff0d72, $amount: 20) !important;
            }
        }
        .photo {
            @include _PHOTO_SHADOW_OUTLINE_ME();
        } 
    }
    .content .___body___{
        transition: 300ms background;
    }
    &._push_{
        .content .___body___{
            background-color: #95003978;
        }
    }

    &._editor_{
        textarea{
            background: $background_color_editor;
        } 
        .tox-editor-container{
            background: $background_color_editor;
            > div[role='group']{
                background-color: #091521;
            }
        }
        .tox-statusbar{
            display: none;
        }
        .footer{
            .boton_editor{
                user-select: none;
                background: $acent_dark ;
                &:hover{
                    background: darken($acent_dark, 5%*0.5) ;
                }
                &:active{
                    background: lighten($acent_dark, 8%*0.5) ;
                }
                // border-color: darken($color: $acent_dark, $amount: 20);
                .icon{
                }
                .text{
                }
            }
        }
        
        button[title="Pantalla completa"]{
            display: none;
        }
        .isMobile &{
            button[title="Pantalla completa"]{
                display: inherit;
            }
        }
    }

    .photo{
        @include _PHOTO_SHADOW_OUTLINE();
    }
    .content{
        // border-color: $border_color_container;
        .header{
            .name{
                color: $text_color_user;
                &:hover{
                    color: lighten($color: $text_color_user, $amount: 10);
                }
            }
            .username{
                color: $text_color_user_id;
            }
            .created{
                color: $userlike_button_active;
            }
        }
        .___body___{
            @include USER_CONTENT();
            a[etiqueta],span[etiqueta] {
                //color: #fb9def;
                color: $etiqueta_user;
                text-decoration: none;
                &:hover{
                    color: lighten($color: #eca5da, $amount: 6);
                }   
            }
        }
        .footer{
            .LinkIrAlPost{
                color: $text_color_titulo-disabled;
            }
        }
    }
}


.BtnFoo{
    user-select: none;
    .hijo{
        div{
            border-color: #DDD;
            .dark &{ border-color: $comment_border_boton; }
        }
    }
    ._r{
        svg{
            fill: #8ac3ff;
            .dark &{ fill: $userlike_button_active; }
        }
        &:hover{
            background-color: #f5f5f5;
            .dark &{ background-color: darken($color: $comment_backgraund_boton, $amount: 50); }
            
            svg{
                fill: lighten($color: $userlike_button_active, $amount: 20);
            }
        }
        &:active{
            background-color: darken($color: $comment_backgraund_boton, $amount: 0);
            svg{
                fill: lighten($color: $userlike_button_active, $amount: 30);
                transition: 100ms;
            }
        }
    }
    

    ._l > span{
        color: $userlike_button_active;
        text-decoration-color: #57a8ff;
        border-color: $comment_border_boton;
        transition: 80ms;
        // background-color: darken($color: $comment_backgraund_boton, $amount: 40);
    }

    &.like{
        .nocero{
            color: $comment_like_foreground_boton;
        }
        &:hover{
            ._r svg{
                // background-color: darken($color: $comment_like_backgraund_boton_active, $amount: 5);
                fill: $comment_like_foreground_boton_active;
            }
            ._l span{
                color: $comment_like_foreground_boton_active;
                // background-color: darken($color: $comment_like_backgraund_boton_active, $amount: 20);
            }
        }
        &:active{
            ._r{
                //background-color: darken($color: $comment_like_backgraund_boton_active, $amount: 5);
                svg{
                    //fill: lighten($color: $comment_like_foreground_boton_active, $amount: 20);
                }
            }
        }
        &.active{
            .hijo{
                div{
                    border-color: transparentize($color: $comment_like_foreground_boton, $amount: 0.7);
                }
            }
            ._r {
                //background-color: #0c1d11;
                svg{
                    fill: $comment_like_foreground_boton;
               }
            }
            ._l{
                //background-color: darken($color: $comment_like_backgraund_boton_active, $amount: 20);
                span{
                    color: $comment_like_foreground_boton;
                }
            }
        }
    }
    &.dislike{
        .nocero{
            color: #ff5877
        }
        &:hover{
            .hijo{
                div{
                    //border-color: transparentize($color: lighten($color: #ff5877, $amount: 10), $amount: 0.9);
                }
            }
            ._r svg{
                // background-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 5);
                fill: lighten($color: #ff5877, $amount: 10);
            }
            ._l span{
                color: lighten($color: #ff5877, $amount: 10);
                // background-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 20);
            }
        }
        &:active{
            ._r{
                //background-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 5);
                svg{
                    //fill: lighten($color: $comment_delete_foreground_boton, $amount: 20);
                }
            }
        }
        &.active{
            .hijo{
                div{
                    border-color: transparentize($color: #ff5877, $amount: 0.7);
                }
            }
            ._r{
                //background-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 13);
                svg{
                    fill: #ff5877;
                    // border-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 25);
                }
            }
            ._l {
                //background-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 28);
                span{
                    color: #ff5877;
                    // border-color: darken($color: $comment_dislike_backgraund_boton_active, $amount: 25);
                }
            }
        }
    }
    &.shared{
        .nocero{
            color: $comment_shared_foreground_boton;
        }
        &:hover{
            ._r svg{
                // background-color: darken($color: $comment_shared_backgraund_boton_active, $amount: 5);
                fill: $comment_shared_foreground_boton_active;
            }
            ._l span{
                color: $comment_shared_foreground_boton_active;
                // background-color: darken($color: $comment_shared_backgraund_boton_active, $amount: 20);
            }
        }
        &:active{
            ._r{
                //background-color: darken($color: $comment_shared_backgraund_boton_active, $amount: 5);
                svg{
                    //fill: lighten($color: $comment_shared_foreground_boton_active, $amount: 20);
                }
            }
        }
        &.active{
            .hijo{
                div{
                    border-color: transparentize($color: $comment_shared_foreground_boton, $amount: 0.7);
                }
            }
            ._r {
                //background-color: #0c1d11;
                svg{
                    fill: $comment_shared_foreground_boton;
               }
            }
            ._l{
                //background-color: darken($color: $comment_shared_backgraund_boton_active, $amount: 20);
                span{
                    color: $comment_shared_foreground_boton;
                }
            }
        }
    }
}


.simple_tooltip{
    *[tabindex]{
        outline: none;
    }
    // z-index: 1202;
    .dropdown-menu{ z-index: 1202; }
    .more{
        .material-icons.puntos{
            color: $userlike_button_active;
            &:hover{
                color: lighten($color: $userlike_button_active, $amount: 10);
            }
        }
    }
} 