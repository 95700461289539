@import "./vals";

@import './post/body.scss';

.___body___{
    @include BODYCSS();
}

@import './default/other.scss';
@import './default/post.scss';
@import './default/comments.scss';
@import './default/login.scss'; 
@import './default/register.scss'; 
@import './default/user.scss'; 
@import './default/header.scss'; 
@import './default/notifications.scss'; 
@import './default/setting.scss'; 
@import './default/home.scss'; 
@import './default/editor.scss'; 
//@import './default/body.scss'; 
@import './default/mensajes.scss'; 
@import './default/random.scss'; 
@import './default/emoticones.scss'; 
@import './default/shouts.scss'; 
@import './default/mi.scss'; 

@import './resources/index.scss';

._classic_{
    max-width: 1200px;
    margin: 0px auto;
}
._classic2_{
    max-width: 1000px;
    margin: 0px auto;
}
._classic3_{
    max-width: 800px;
    margin: 0px auto;
}

.inline-block{
    display: inline-block;
}

.componen_message{
    font-size: 24px;
    .title{
        font-size: 72px;
    }
}

// footer{
//     padding-bottom: 15px;
//     text-align: center;
//     span{
//         cursor: pointer;
//         margin-right: 20px;
//         &[last]{
//             margin-right: 0px;
//         }
//     }
// }

#wrapper {
    min-height: 100vh;
    margin-bottom: 0px; /* the bottom margin is the negative value of the footer's total height */
    .isDesktop &, .isTablet &{
        height: auto !important;
    }
}

#wrapper:after {
    content: "";
    display: block;
    height: 50px; /* the footer's total height */
}

#content {
    // height: 100%;
    .isDesktop &{
        margin-bottom: 0px;
    }
}

.gway5767gaw{
    padding: 3px 5px;
    border-radius: 3px;
    display: inline-block;
    margin: 1px 0px;
    &.comment_delete_my{
        color: #627d98 !important;
        background: #0f171f;
    }
    &.comment_delete_other{
        color: #a98686 !important;
        background: #400e0e;
    }
}
.nowrap{
    white-space: nowrap;
}
.Titillium{
    font-family: 'Titillium Web', sans-serif;
}
.PassionOne{
    font-family: 'Passion One', cursive;
}
.nofound{
    color: #ff0052;
    text-align: center;
}
.px100{
    font-size: 100px;
}
.max-width-none{
    max-width: none;
}
.iralcielo{
    background-color: rgba(0,0,0,0.7);
    opacity: 0.7;
    border: 0;
    border-radius: 3px;
    position: fixed;
    bottom: 11px;
    right: 11px;
    z-index: 1024;
    height: 30px;
    width: 30px;
    padding: 5px;
    svg{
        fill: white;
        transform: translate(0px, -2px);
    }
}

a.underline{
    text-decoration: underline; 
}
.favdeletecontent{
    margin-bottom: 10px;
    border-bottom: 1px solid #25455a;
}
.favdelete{
    background: #0d1217;
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0px;
    margin-bottom: 10px;
    border-bottom: 1px solid #25455a;
}
.modal-dialog-scrollable{
    &.FavMobileFixer{
        max-height: calc(100% - 4rem);
        @include res(576px){
            max-height: calc(100% - 6rem) !important;
        }
        &.favline{
            border-bottom: 1px solid #25455a;
        }
    }
}

.panel23{
    .nav-options{
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0px, -50%);
        margin: 0px 15px;
    }
}

.happy_hat1{
    position: absolute;
    transform: rotate(-15deg) scaleX(-1);
    left: -3.2px; 
    top: -5px;   
}

button.style.btn-style-error{
    border-color: #8e223a;
    background-color: #632525;
    transition: transform 100ms;
    &:hover, &:focus{
        border-color: lighten($color: #8e223a, $amount: 5) !important;
        background-color: lighten($color: #632525, $amount: 5) !important;
    }
    &:active{
        border-color: lighten($color: #8e223a, $amount: 8) !important;
        background-color: lighten($color: #632525, $amount: 8) !important;
        transform: scale(0.985);
    }
}

button:focus{
    outline: none;
}


.text-box-3{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
} 
.text-box-2{
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
} 
.text-box-1{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
} 


.unm-1{
    padding: 0.25rem;
    margin: -0.25rem;
}
.unm-2{
    padding: 0.5rem;
    margin: -0.5rem;
}
.unm-3{
    padding: 0.75rem;
    margin: -0.75rem;
}
.unm-4{
    padding: 1rem;
    margin: -1rem;
}