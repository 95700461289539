.logo-event-navidad{
    &-gorro{
        position: absolute;
        left: -9px;
        top: -6px;
        height: 13px;
        object-fit: cover;
        transform: rotate(-10deg);
    }
    &-regalo{
        position: absolute;
        left: -35px;
        bottom: -3px;
        height: 25px;
        object-fit: cover;
        opacity: 30;
        // transform: rotate(10deg);
    }
}