
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  
@import "./z-index.scss";
@import "./resources/tachyons";
@import '../styles/bootstrap.min.css';

@import './resources/basic.scss';
@import 'C:/Users/samab/AppData/Roaming/Code/User/globalStorage/royaction.color-manager/1PColors.scss';

@import './default.scss';
@import './dark.scss';
@import 'document.scss';

@import '../styles/tailwind.build.css';

$acento: #57a8ff;
$acento_light: lighten($color: $acento, $amount: 35);
$acento_hover: darken($color: $acento, $amount: 5);
$acento_active: lighten($color: $acento, $amount: 5);

.seed{
    content: "PsklSExMgd";
}

a{
    color: $acento;
}
 
.user-conteiner, .user-setting{
    max-width: 300px;
} 
 
   
.button-basic {
    background: #dae3ff;
    border: 0px;
    padding: 15px 15px;
    display: block;
    width: 100%;
    /* position: absolute; */
    margin: 0 auto;
    border-radius: 5px;
}

.box-basic {
    color: #e87676;
    /* font-family: cursive; */
    text-align: center;
    font-size: 12px;
    margin: 25px 0px 15px 0px;
    font-family: sans-serif;
    h2 {
        margin: 10px;
        color: #72abff;
    }
}

textarea.basic{

    width: 89.5%;
    padding: 10px;
    background: #dae3ff;
    border: 0px;
    padding: 15px 15px;
    border-radius: 5px;
    
    min-height: 60px;
    min-width: 100%;
    max-width: 100%;
}

input[type="text"], input[type="password"] , textarea {
    width: 100%; 
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    .mui-fix-input &{
        box-sizing: content-box;
    }
}

.nav-link{
    padding: 7px 15px !important;
}
.nav-link.active{
    border: 1px solid $acento;
}
body > iframe{
    display: none;
}

.media-twitter{
    background-color: #1d2333;
    > div{
        padding-right: 10px;
        padding-left: 10px;
        .twitter-tweet{
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}
// .media-twitter{
//     background-color: #151f2b;
//     > div{
//         overflow: hidden;
//         .twitter-tweet{
//             display: flex;
//             // width: 100%;
//             margin-top: 0px;
//             margin-bottom: 0px;
//             margin-left: -5px;
//             margin-right: -5px;
//             iframe{
//                 margin-top: -15px;
//                 margin-bottom: -15px;
//             }
//         }
//     }
// }