.page-login {
    .form-signin{
        @include _PANEL();
        @include _FORM();
        input{
            margin-bottom: 5px;
            color: #7cbcfe;
        }
        button{
            background: transparent;
            color: #7cbcfe;
            border-color: #7cbcfe4f;
            &:hover{
                border-color: #3a6796;
            }
            &:active{
                box-shadow: none !important;
                background: #0e151d !important;
                color: #5580ad !important;
                border-color: #607a944f !important;
            }
        }
        .message.error {
            color: #e60175 !important;
        }
        .message_green {
            color: #82ff91;
        }
    }
}