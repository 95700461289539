
.editor_button_mobile{
    display: block;
    height: 30px;
    margin-top: -12px;
    padding-top: 4px;
    text-align: center;
    a{
        color: #ff56ab !important;
        margin-right: 6px;
        float: right;
    }
    @media screen and (min-width: 420px){
        display: none;
    }
}

.page-home{
    > .row{
        margin-left: 0px;
        margin-right: 0px;
        // .isTablet &{
        //     margin-left: $desktop_margin_page;
        //     margin-right: $desktop_margin_page;
        // }
        // .isMobile &{
        //     margin-left: $mobile_margin_page;
        //     margin-right: $mobile_margin_page;
        // }
    }
    .secionA{
        min-height: 100px;
        padding-top: 10px;
        height: fit-content;

        .isMobile &{
            padding-left: $mobile_padding_page;
            padding-right: $mobile_padding_page;
            border-top: none;
            min-height: calc(100vh - 48px);
            .componen_loading .conteiner{
                align-items: center;
            }
        }

        .panel{
            min-height: 100px;
        }
    }
    .secionB{
        // margin-left: 10px;
        // padding-left: 0px;
        padding-right: 0px;
        .panel{
            min-height: 250px;
            padding-top: 10px;
            margin-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .nav-link.active{
        border: 0px;
        border-bottom: 1px solid;
    }
    .header_descatados{
        a{
            display: inline-block;
            margin: 0px;
            padding: 0px;
            background: #0f171f;
            padding: 5px 8px 3px 8px;
            border-radius: 5px;
            color: #67b8ff;
            border: 1px solid #57a8ff30;
            font-size: 12px;
        }
    }
    .shouts_destacados{
        .nav-link.active{
            background: transparent !important;
            border-bottom-color: #5b6d86!important;
            color: #5b6d86 !important;
        }
    }
    .tops_de_la_semana{
        .isNotMobile &{
            padding-bottom: 5px;
        }
    }
    .home_comentarios_recientes{
        ._comment_:last-child{
            > .content{
                border-color: transparent;;
                margin-bottom: 5px;
            }
        }
    }
}
.nav-tabs.tabs23{
    .nav-link.active{
        border: 0px;
        border-bottom: 1px solid;
    }
}
.post_mini{
    // margin-top: 5px;
    // margin-bottom: 5px;
    // padding-top: 5px;
    // padding-bottom: 5px;
    position: relative;
    
    .isDesktop &{
        .simple_tooltip .material-icons{
            opacity: 0;
        }
        &:hover{
            .simple_tooltip .material-icons{
                opacity: 1;
            }
        }
    }
    .photo{
        width: 90px;
        height: 67px;
        border-radius: 5px;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        // .isMobile &{
        //     width: 67px;
        //     height: 67px;
        // }
        &._100{
            width: 100%;
            height: 200px;
        }
    }
  

    .options{
        position: absolute;
        right: 0px;
        top: 0px;
    }
    &.media{
        .media-body{
            width: calc(100% - 90px - 2.25rem);
        }
    }
    .media-body{
        margin-bottom: -5px;
        flex: none;
        .stickyTag{ 
            /* padding: 3px 5px 3px 5px; */
            border-radius: 5px;
            color: white;
            background: #3e7cbf;
            font-size: 14px;
            /* transform: translate(0, -3px); */
            height: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding-left: 4px;
            padding-right: 4px;
            font-size: 12px;
        }
        h5{
            word-break: break-word;
            text-decoration: none !important;
        }
    }

    &._sticky_ {
        margin: 0px;
        padding: 0px;
        .photo{
            height: 30px;
        }
    }
    &._stycky_large_{
        .photo{
            height: 55px;
        }
    }
    
    ._mini &{
        // margin-top: 5px;
        // margin-bottom: 5px;
        .photo{
            width: 60px;
            height: 42px;
        }
        &._sticky_ {
            .photo {
                height: 25px;
            }
        }
        &._stycky_large_ {
            margin-top: 5px;
            margin-bottom: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            .photo{
                height: 45px;
            }
        }

        h5{
            font-size: 15px;
        }
        &.media{
            .media-body{
                flex: none;
                width: calc(100% - 60px - 2.25rem);
            }
        }
        .media-body{
        }
        &._noneed{
            .photo{
                height: 16px;
            }
            h5{
                font-size: 10px;
                padding-top: 2px;
                max-height: 25px !important;
            }
            .material-icons,.info-icon {
                svg{
                    width: 11px;
                    transform: translate(0px, 2px);
                }
            }
            
            span{
                font-size: 10px;
                max-height: 25px !important;
            }
            &:hover{
                .media-body{
                    margin-top: -5px;
                }
            }
        }
    }

    &._noneed{
        margin: 0px;
        padding: 0px;
        .photo{
            height: 28px;
        }
        .title.span{
            font-size: 18px;
            max-height: 15px;
        }
        h5{
            font-size: 18px;
            max-height: 15px;
        }
        .title{
            margin-right: 10px;
        }
        .post_mini-blurshow{
            padding: 0px 10px !important;
            font-size: 12px !important;
        }
        .media-body{
            &-hidden{
                display: none;
            }
        }
        &:hover{
            .media-body{
                &-hidden{
                    display: block;
                }
                &-normal{
                    display: none;
                }
            }
        }
    }
    &._noveo{
        .photo{
            opacity: 0.3;
            transition: opacity 120ms;
        }
        .media-body{
            opacity: 0.3;
            transition: opacity 120ms;
        }
        &:hover{
            .photo{
                opacity: 1;
            }
            .media-body{
                opacity: 1;
            }
        }
    }
    &._blur{
        .photo{
            filter: blur(5px);
            opacity: 0.2;
        }
        .media-body{
            filter: blur(5px);
            opacity: 0.2;
        }
        .post_mini-blurshow{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);

            font-size: 15px;
            padding: 5px 10px;
            background: #fff1;
            border: none;
            z-index: 1;
        }
        .dropdown-menu{
            z-index: 1000;
        }
    }
    &._reco{
        display: block;
        .photo{
            margin-bottom: 13px;
        }
    }
}
