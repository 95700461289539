
.halloween[alt="caldero"]{
    position: absolute;
    left: -33px;
    top: -12px;
    height: 40px;
    object-fit: cover;
    transform: rotate(360deg);
}
.halloween[alt="calabaza"]{
    position: absolute;
    left: -1px;
    top: -10px;
    height: 10px;
    object-fit: cover;
    transform: rotate(360deg);
}
.halloween[alt="vela"]{
    position: absolute;
    left: 130px;
    top: 5px;
    height: 15px;
    object-fit: cover;
    transform: rotate(360deg);
}
.halloween[alt="calavera"]{
    position: absolute;
    left: 108px;
    top: -5px;
    height: 35px;
    object-fit: cover;
    transform: rotate(360deg);
}
.halloween[alt="arana"]{
    position: absolute;
    left: 128px;
    top: -35px;
    height: 65px;
    object-fit: cover;
    transform: rotate(360deg);
    filter: invert(1);
    z-index: 0;
}
.halloween[alt="arana2"]{
    position: absolute;
    left: -38px;
    top: -25px;
    height: 45px;
    object-fit: cover;
    transform: rotate(280deg);
    filter: invert(1);
    opacity: 0.8;
}