
@import "./../vals.scss";
@import "./32_vals.scss";

@mixin _FORM() {
    label{
        color: $text_color_small;
    }
    input,select,textarea{
        background-color: $background_color_editor;
        color: $text_color;
        &:focus{
            background-color: lighten($color: $background_color_editor, $amount: 5);
        }
    }
    input[disabled],select[disabled],textarea[disabled]{
        background-color: lighten($color: $background_color_editor, $amount: 4);
        opacity: 0.5;
    }
    // .input-group-text{
    //     background-color: $background_shadow_small_color;
    // }
} 

@mixin _PANEL() { 
    border-top: 1px solid; 
    border-color: $background_shadow_small_color;
    background: $background_color_content;
    border-radius: 5px;
    border: none;
    .isMobile &{
        border-radius: 0px;
    }
} 


@mixin _PHOTO_SHADOW($shadow: 1) {
    border: $avatar_border;
    @if $shadow == 1 {
        box-shadow: $avatar_shadow;
    } 
    &:hover{
        border-color: lighten($color: $acent_dark, $amount: 10);
    }
}

@mixin _PHOTO_SHADOW_OUTLINE($shadow: 1) {
    outline: $avatar_outline;
    outline-width: 0px;
    @if $shadow == 1 {
        box-shadow: $avatar_shadow;
    } 
    &:hover{
        outline-width: 1px;
        outline-color: lighten($color: $acent_dark, $amount: 10);
    }
}
@mixin _PHOTO_SHADOW_OUTLINE_ME($shadow: 1) {
    outline: 1px solid #ff006a50;
    @if $shadow == 1 {
        box-shadow: 0 0 15px 0px #ff0b692d;
    } 
    &:hover{
        outline-color: lighten($color: #ff006a50, $amount: 20);
    }
}