.page-categorys{
    @include _FORM();
}


.page-category_uff{
    .page-conteiner{
        @include _PANEL();
    }
    .message32{
        color: $text_color_titulo-disabled;
    }
}

.container-protocol{
    b{
        color: $itempublicacion_dislike_icon_backgraund_active;
    }
     
}

.page-info{
    .page-conteiner{
        @include _PANEL();
    } 
    .message32{
        color: $text_color_titulo-disabled;
    }
}

.history-scroll{
    span.system {
        color: #57a8ff;
    }
    span.mod {
        color: #6e1542;
    }
    span.user {
        color: #317c9d;
    }
    span._hidden_{
        color: #5ce1ff;
    }
    .list-unstyled{
        margin-top: 10px;;
    }
    time {
        color: #6b6b6b;
    }
}

.rawhtml{
    margin: 10px; 
    ul{
        margin: 0px 30px;
    }
}

.user_item53{
    vertical-align: top;
    display: inline-block;
    width: 230px;
    .photo{
        width: 41px;
        height: 41px;
        border-radius: 3px;
        object-fit: cover;
        @include _PHOTO_SHADOW();
    }
    .media-content{
        margin-left: 10px;
    }
    .metas{
        font-size: 10px;
    }
}

.color_rosa{
    color: $text_color_user_id;
}
.color_user{
    color: $text_color_user;
}
.color_red{
    color: #ea5b5b !important;
}
.color_time{
    font-size: 12px;
    transform: translate(0px, -1px);
    .__shared_author__ &{
        display: inline;
    }
}
.color_username{
    color: $text_color_user_id;
}

.color_green {
    color: #00ffb8 !important;
}
.global_setting{
    @include _FORM();
}


.page-terminos{
    ._9palU ul {
        color: $acent_dark;
    }
    ._9palU p {
        color: $text_color;
    }  
    ._9palU h3, ._9palU h4 {
        color: $text_color_titulo;
    }
}