@import "./../../../../scss/vals";

.tags{
    overflow-wrap: anywhere;
    &-item{
        $col: #a1c9e1;
        color: $col;
        &:active{
            color: lighten($color: $col, $amount: 4);
        }
        &.Jy14Y{
            color: #21c2ff ;
            &[data-tag="nsfw"]{
                color: #ffaaaa;
            }
        }
        &.zp1ou{
            color: #34ff98;
            &[data-tag="nsfw"]{
                color: #ff5a5a;
            }
        }
        &.fNntB{
            color: #a1c9e1;
            &[data-tag="nsfw"]{
                color: #ff5a5a;
            }
        } 
    }
}

.tagView{
    background: $backgraund_puntos;
    border-radius: 5px;
    width: min-content;
    &-clear{
        border-radius: 5px;
        background: $backgraund_puntos;
        border: none;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 150ms;
        &:hover{
            background: lighten($color: $backgraund_puntos, $amount: 5);
        }
        &:active{
            transform: scale(0.98);
            background: lighten($color: $backgraund_puntos, $amount: 10);
        }
    }
    &-name{
        color: #a1c9e1;
        font-weight: bold;
    }
}