.messagen-container{
    position: relative;
}
/* .isMobile .messagen {
    position: fixed;
    left: 0px;
    bottom: 0px;
} */

.messagen {
    z-index: 1060;
}
@media screen and (max-width: 768px) {
    .messagen {
        position: fixed;
        left: 50%;
        top: 5px;
        transform: translate(-50%,0px);
    }
}
@media screen and (min-width: 768px ){
    .messagen {
        position: fixed;
        left: 0px;
        bottom: 0px;
    }
}

span.message {
    padding: 5px 10px;
    margin: 0px 0px 10px 10px;
    background: #245194e6;
    border: 1px solid #0f171f;
    border-radius: 5px;
    display: block;
    color: white;
    font-family: monospace;
    transition: opacity 0.3s;
    
    z-index: 1060;
}

span.message.green {
    background: #1b772ae6;
    border: 1px solid #0d310c;
    color: #d8ffd7;
}

span.message.red {
    background: #771b1be6;
    border: 1px solid #310c0c;
    color: #ffd7d7;
}

span.message.purpure {
    background: #771b39e6;
    border: 1px solid #3a0e1f;
    color: #ffd7d9;
}
span.message.orange {
    background: #774a1be6;
    border: 1px solid #31200c;
    color: #fffed7;
}

span.message.hden{
    opacity: 0;
}
