.page-categorys{
    .category {
        background: #0f171f;
        padding: 5px 8px;
        margin-bottom: 8px;
        border-radius: 5px;
    }
    select {
        display: none !important;
        .isMobile &{
            display: inherit !important;
        }
    }
    .panel_portada{
        display: inherit !important;
        .isMobile &{
            display: none !important;
        }

    }
    
} 

.page-category_uff{
    .page-conteiner{
        padding: 5px 10px;
    }
}

.page-plantilla{
    .title{
        word-break: break-all;
        word-wrap: break-word !important;
        max-width: 100%;
    }
}

.container-protocol{
    padding: 30px 50px !important;
    li{
        margin-bottom: 15px;
    }
    h3{
        margin-top: 20px;
    }
}

.page-terminos{
    ol{
        padding-left: 40px;
    }
    ._9palU ul {
        list-style-type: disc;
        padding-left: 40px;
    }
    ._3miN5 ol {
        list-style-type: none;
    }
}

.page-emoticones{
    .___body___{
        text-align: center;
        padding: 10px 0px;
    }
    .emoticon-conteiner{
        display: inline-block;
        width: 100px;
        height: 100px;
        text-align: center;
        font-size: 12px;
        img{
            width: 50px !important;  
            image-rendering: pixelated;
            border-radius: 0 !important;
        }
        .s404{
            font-size: 40px;
        }
        .isMobile &{
            width: 80px;
            height: 80px;
            img{
                width: 40px;
                image-rendering: pixelated;
            }
            .s404{
                font-size: 24px;
            }
        }
    }
}

.home-stats.popup{
    h5.c1{
        color: #3ff733;
    }
    h5.c2{
        color: #04fdfd;
    }
    h5.c3{
        color: #f73345;
    } 
}