.page-home{
    // .secionA{
    //     @include _PANEL();
    // }
    // .secionB{
    //     .panel{
    //         @include _PANEL();
    //     }
    // }
    .nav-tabs{
        border-bottom-color: #57a8ff33;
        .nav-item{
            .nav-link{
                color: $comment_text_boton_ytu;
                svg{
                    fill: $comment_text_boton_ytu !important;
                    g{
                        fill: inherit;
                    }
                    transform: translate(0px, -1px);
                    &.fire{
                        transform: translate(0px, -2px);
                    }
                }
            } 
            &.tops{
                // position: absolute;
                // right: 0px;
                // top: -1px;
                display: none;
                .isMobile &{
                    display: list-item;
                }
            }
        }
        .nav-link.active{
            background-color: $background_color_content;
            border-bottom-color: #57a8ff;
            color: #77b8fd;
            svg{
                fill: $text_color !important;
            }
        }
        .editor_button{
            position: absolute;
            right: 0px;
            top: -1px;
            display: list-item;
            a{
                padding: 3px 15px 5px 15px !important; 
            }
        }
    }
}

.nav-tabs.tabs23{
    border-bottom-color: #57a8ff33;
    .nav-item{
        .nav-link{
            color: $comment_text_boton_ytu;
        } 
        &.tops{
            // position: absolute;
            // right: 0px;
            // top: -1px;
            display: none;
            .isMobile &{
                display: list-item;
            }
        }
    }
    .nav-link.active{
        background-color: $background_color_content;
        border-bottom-color: #57a8ff;
        color: $text_color;
    }
    .editor_button{
        position: absolute;
        right: 0px;
        top: -1px; 
        display: list-item;
        a{
            padding: 3px 15px 5px 15px !important; 
        }
    }
}
body{
    font-family: Lato, Roboto, Helvetica, Helvetica Neue, Arial, sans-serif !important;
}
.post_mini{
    .photo{
        box-shadow: $avatar_shadow;
    }
    .media-body{
        .material-icons,.info-icon {
            .fill{
                fill: $text_color;
            }
        }
        h5{
            font-weight: bold;
            letter-spacing: .3px;
            text-overflow: ellipsis;
            line-height: 24px;
            color: $miniaturapost_title_color;
            font-size: 18px;
            overflow: hidden !important;
            display: -webkit-box !important;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 2 !important;
            &:hover{
                color: lighten($color: $miniaturapost_title_color, $amount: 10);
            }
        }
        > span{
            color: $text_color;
        }
        a{
            color: $miniaturapost_link_color;
        }
    }
    &._sticky_{
        .media-body{
            h5{
                line-height: 26px;
            }
        }
    }
}

header{
    .editor_button{
        margin-right: 8px;
    }
}

.editor_button{
    display: inline-block;
    a{
        color: #ffd9f1 !important;
        background: #6f1342;
        border-radius: 5px;
        font-weight: bold;
        /* height: 30px; */
        padding: 8px 15px !important;
        transition: 300ms;
        @media screen and (max-width: 420px){
            display: none;
        }
        &:hover{
            color: lighten($color: #ffd9f1, $amount: 10) !important;
            background-color: lighten($color: #6f1342, $amount: 10);
            text-shadow: 0 0 20px #ff25a5;
            box-shadow: 0 0 13px 0px #6f1342;
        } 
        &:active{
            color: lighten($color: #ffd9f1, $amount: 14) !important;
            background-color: lighten($color: #6f1342, $amount: 14);
        }  
    }
}

.onlineusers{
    &:hover{
        border-color: lighten($color: $acent_dark, $amount: 10);
    }
  }