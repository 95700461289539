header{
    .button_notificaciones{
        .dropdown-menu{
            @include _PANEL();
            padding-bottom: 6px;
            padding-top: 0px;
        } 
        // height: 30px;
        // display: block; 
    }
    .header_notis_link{
        color: #317c9e;
    }
}
