
header{
    @media screen and (max-width: 350px){
        height: 100px !important;
    }
    ul.pure-menu-list{  
        .isMobile &{
            font-size: 12px;
        }
    } 
    .miniature_header_user{
        background: #6f1342;
        padding: 5px 8px 5px 7px;
        border-radius: 5px;
        user-select: none;
        .photo{
            margin-right: 7px;
            display: inline-block;
            background-size: contain;
            transform: translate(0px,-1px);
            vertical-align: middle;
            border-radius: 5px;
            width: 27px;
            height: 27px;
            background-size: cover;
            background-position: center;
        }
        .name{
            display: inline-block;
            vertical-align: middle;
            transform: translate(0px, -1px);
            font-weight: bold;
            color:white;
        }
    }
    .pure-menu-list {

        > span{
            display: inline-block;
        }
    }

    .header_notis{
        .material-icons{
            font-size: 20px !important;
            transform: translate(-5px,7px) !important;
            display: inline-block !important;
            margin: 0px 10px;
        }
        .num{
            font-size: 10px;
            bottom: 0px;
            left: 0px;
            background: #6f1442;
            padding: 3px 4px 3px 4px;
            border-radius: 3px;
            transform: translate(0px,0px) !important;
            display: inline-block !important;
        }
        .simple_tooltip{
            background: red !important;
        }
    }
    position: relative;
    .header-right{
        position: absolute; 
        right: 0px;
        top: 50%;
        transform: translate(0,-50%);
        z-index: 100;
    }
 
    @media screen and (max-width: 767px){
        .miniature_header_user{
            .photo{
                margin-right: 0px;
            }
            .name{
                display: none !important;
            }
        }
    }
}