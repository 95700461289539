@mixin _Comments55Dark() {
    .comments{
        @include _PANEL();
        .title{
            color: $text_color_small;
            span{
                color: $text_color_titulo-disabled;
            }
        }
        .light_message .message{
            color: $text_color_light;
        }
    }
}

    .page-post{
        .isMobile &{
            min-height: calc(100vh - 50px);
            background: $background_color_content;
        }
        article._post_{
            // @include _PANEL();
            .___title___{
                .title{
                    color: $text_color_titulo;
                } 
            }
            .options{
                color: #aaa;
                .category{
                    color: $text_color_url;
                    
                    &:hover{
                        color: lighten($color: $text_color_url, $amount: 20);
                    }
                    &:active{
                        color: lighten($color: $text_color_url, $amount: 10);
                    }
                }
            }
            .body{
                @include USER_CONTENT();
            }
        }
        .puntuo{
            span{
                color: $comment_text_boton_ytu;
            }
        }
        .puntear{
             .puntos span{
                color: $text_color_puntos;
                text-shadow: $text_color_puntostext_shadow;
                background: $backgraund_puntos;
                &:hover{
                    box-shadow: $text_color_puntostext_shadow_hover;
                }
                &:active{
                    background: #274e7e;
                    color: white;
                }
            }
             
            div{
                color: $text_color_light;
            }
        }
        .metas{
            color: $comment_text_boton_ytu;
        }
        .panel{
            @include _PERFIL_DARK();
        }
        @include _Comments55Dark();
    }
    .user_perfil_mobile{
        .photo{
            @include _PHOTO_SHADOW();
            border-radius: 4px;
        }
        .username{
            color: $text_color_user_id;
        }
        .puntos{
            color: #5ac3ff;
        }
    }

    .css_commentslist{
        @include _Comments55();
        @include _Comments55Dark();
        .isMobile & {
            margin-top: 0px !important;
        }
    }
    
    .panel_style0{
        border-bottom-color: #57a8ff33 !important;
        .nav-link.active{
            border-top: none;
            border-left: none;
            border-right: none;
            background: transparent !important;
            border-bottom-color: #5b6d86!important;
            color: #5b6d86 !important;
        }
    }
    .MiniaturaPanel{
        overflow: hidden;
        img{
            width: 100%;
            @include _PHOTO_SHADOW();
            border-radius: 5px;
        }
        .fill{
            fill: $text_color_url;
            transform: translate(0px,-10px);
        }
        a{
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }