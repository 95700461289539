
.userpanel{
    @include _PERFIL();
}

.page-user{
    .row{
        margin-left: 0px;
        margin-right: 0px;
        // .isTablet &{
        //     margin-left: $desktop_margin_page;
        //     margin-right: $desktop_margin_page;
        // }
        // .isMobile &{
        //     margin-left: $mobile_margin_page;
        //     margin-right: $mobile_margin_page;
        // }
    }
    > div >.content{
        min-height: 100px;
        padding-top: 13px;
        height: fit-content;
        .nav-link.active{
            border: 0px;
            border-bottom: 1px solid;
        }
        .isMobile &{
            padding: $mobile_padding_page*0.5;  
            padding-left: $mobile_padding_page;
            padding-right: $mobile_padding_page;
        }
    }
}
.row23{
    margin-left: 0px !important;
    margin-right: 0px !important;
    // .isTablet &{
    //     margin-left: $desktop_margin_page !important;
    //     margin-right: $desktop_margin_page !important;
    // }
    // .isMobile &{
    //     margin-left: $mobile_margin_page !important;
    //     margin-right: $mobile_margin_page !important;
    // }
    
    .content25,.panel25{
        padding-left: 0px;
        padding-right: 0px;
    }
    .panel25{
        .isNotMobile &{
            padding-left: 10px !important;
        }
    }
    .panel23{
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        min-height: 50px;
        @include _PERFIL();
        .isMobile &{
            padding-left: 10px;
            padding-right: 10px;
        }
        // .isNotMobile &{
        //     // margin-bottom: 0px !important;
        // }
    }
}