.page-mi{
    .shouts.panel23{
        //padding: 10px;
        padding-top: 5px;
        background: transparent;
        border: none;
    }
    .list-unstyled{
        margin-top: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    
    .isMobile &{
        .shouts.panel23{
            padding-top: 1px;
        }
        > .row {
            margin-right: 0px;
            margin-left: 0px;
            > .content{
                padding-right: 0px;
                padding-left: 0px;
            }
        }
        .nav-link{
            padding: 10px 15px !important;
        }
    }

    .panel23{
        border-radius: 0px;
        padding: 0px;
    }
    
    @media screen and (max-width: 310px){
        .nav-item .nav-link {
            font-size: 11px; 
        }
    }
    .nav-tabs.tabs23 .nav-link.active {
        background-color: transparent;
        color: #57a8ff !important;
        color: #4c7996 !important;
        border-bottom-color: #316384 !important;
        
    }
    .penel412{
        padding: 15px;
        padding-top: 10px;
        border-radius: 5px;
        border: none;
    }
}