
.page-notifications{
    .noti_container{
        @include _PANEL();
    }
}

    .noti_container{
        .noti{
            // border-bottom: 1px solid $text_color_titulo-disabled;
            .header{
                span{
                    color: $text_min;
                }
                .title{
                    color: #aaaa;
                }
            }
            .body{
                color: $text_color;
            }
            // .footer{
            // }
        }
    }