.page-mensajes{

    .noti_mensajes{
        .photo{
            @include _PHOTO_SHADOW();
        }
        .___body___{
            @include USER_CONTENT();
            color: $text_color;
        }
    }
}