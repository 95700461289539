
.isMobile {
    .jXnVrnrLuK{
        min-height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
    }
    .TdyTB27fa3{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
    }
}