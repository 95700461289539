.logo-event-bandera{
    &-bandera_ar{
        position: absolute;
        left: -26px;
        top: 0px;
        height: 15px;
        object-fit: cover;
        transform: rotate(360deg);
    }
    &-escarapela{
        position: absolute;
        left: -21px;
        top: -6px;
        height: 28px;
        object-fit: cover;
        transform: rotate(366deg);
    }
    &-poste{
        position: absolute;
        left: 112px;
        bottom: -2px;
        height: 24px;
        object-fit: cover;
        opacity: 30;
    }
    &-mate{
        position: absolute;
        left: 116px;
        bottom: -4px;
        height: 26px;
        object-fit: cover;
        opacity: 30;
        transform: rotate(11deg);
    }
}