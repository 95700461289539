.page-editor{
    @include _FORM();
    .themain{
        #titulo{
            background: $background_color_content;
            border: none;
            font-size: 24px;
            color: $text_color_titulo;
        }
    }
    .tabs23{
        @include _PANEL()
    }
}

.etiquetas23{
    li{
        color: $etiquetas_post;
        &.ninguna{
            background: #2b4156;
            color: #869cb1;
        }
    }
}