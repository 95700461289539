.__shout__{
    transition: opacity 200ms;

    .header{
        svg{
            transform: translate(0px, -2px);
            margin-right: 5px;
            &.icon_time2{
                transform: translate(0px, -1px);
                margin: -5px;
                margin-right: -2px;
                margin-left: -2px;
            }
        }
    }
    .photo{
        background-size: cover;
        border-radius: 5px;
        width: 40px;
        height: 40px;
    }
    &.media-shared-shout{
        border: none;
        margin-bottom: 0px;
        .___dynbody___{
            img{
                border-radius: 3px;
                border: 1px solid #4ea8ff;
            }
        }
    }
    &.shared-shout{
        border: none;
        margin-bottom: 0px;
        padding: 10px 10px 3px 10px !important;
        background-color: #080d13;
        
        .___dynbody___{
            > a > img{
                border-radius: 5px;
                border: 1px solid #4ea8ff;
            }
        }
        .BtnFoo .hijo div{ 
            border-color: transparent;
            //opacity: 0.5;
        }
    }

    > ._shoutbody_{
        //max-width: 570px;
        > .text{
            margin-top: 1px;
            overflow: hidden;
            word-wrap: break-word;
            overflow-wrap: anywhere;
            a[etiqueta="1"]{
                color: #eca5da;
                &:hover{
                    color: lighten($color: #eca5da, $amount: 6);
                }
            }
        }
    }
        
    .__shared_author__{
        border-radius: 5px 5px 0px 0px ;
        margin-top: -2px;
        padding-left: 10px;
        padding-bottom: 5px;
        border-bottom: none;
        .content{
            margin-top: -3px;
        }
        svg{
            fill: #7fc3ff;
        }
        .simple_tooltip .more .material-icons{
            transform: translate(-3px, -3px);
        }
    }
} 
.A1{
    .BtnFoo{
        margin-right: 7px;
    }
}
.awssld.awssld-organic-arrows{
    transition: height 500ms;
    margin-bottom: 30px;
    .awssld__bullets{
        bottom: 0px !important;
        bottom: -25px !important;
        z-index: 10;
        button{
            width: 10px !important;
            height: 10px !important;
        }
    }
}

.___dynbody___{
    text-align: center;
    > a{
        word-wrap: break-word;
    }
    *{
        max-width: 100%;
    }
    
    .awssld__controls button{
        outline: none;
        span{
            &::before{
                opacity: 0 !important;
            }
            &::after{
                opacity: 0 !important;
            }
        }
    }
    .player-wrapper {
        overflow: hidden;
        &.full{
            &.ratio {
                position: relative;  
                padding-top: inherit !important;
                .react-player {
                    position: inherit !important;
                }
                .isDesktop &{
                    max-height: calc(100vh - 100px);
                }
                .isMobile  &{
                    max-height: calc(100vh);
                }
            }    
        }
        &.ratio {
            position: relative;  
            // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
            padding-top: 56.25%; /* Player ratio: 100 / (1024 / 720) */
            
            .react-player {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .react-player {
            video{
                outline: none;
            }
        }
    }
    
    .media{
        &-link{
            padding: 15px;
            *{
                word-break: break-word;
            }
            &-a_photo{
                max-width: initial !important;
                margin-right: 10px;
                img{
                    object-fit: cover;
                }
                ._corto_ &{
                    display: none;
                }
            } 
            &-photo{
                max-width: initial !important;
                width: 158px;
                height: 158px;
                @include maxres(480px){
                    width: 64px;
                    height: 64px;
                }
            }
            &-title{
                font-size: 24px;
                ._corto_ &{
                    font-size: 18px;
                }
                @include maxres(480px){
                    font-size: 18px;
                }
                margin-bottom: 8px;
            }
            &-content{
                
            }
            &-description{
                font-size: 15px;
                max-height: 63px;
                overflow: hidden;  
                margin-bottom: 8px;
            }
            &-linkcontent{
            }
            &-icon{
                margin-right: 5px;
                display: inline;
                margin-top: 2px;
            }
        }
        &-shout{
            border: 1px solid;
            border-color: #DDD;
            .dark &{ border-color: #111d29; }
            text-align: left;
            .__shout__{
                border: none;
                .dropdown-menu{
                    max-width: none;
                }
            }
        }
    }
    
}

.___dynbodyhidden___{
    overflow: hidden;
    position: relative;
    min-height: 120px;
    text-align: center;
    z-index: 0;
    img.fondo{
        width: 100%;
        object-fit: cover;
        filter: blur(30px);
        position: absolute;
        top: 0;
        //bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        opacity: 0.3;
    }
    .dynbodyhidden{
        &-content{
            z-index: 10;
            margin: 100px 20px;
            z-index: 1; 
            ._corto_ &{
                margin: 20px 20px;
            } 
            
            &-title{
                font-size: 24px;
                ._corto_ &{
                    font-size: 15px;
                    overflow: hidden; 
                } 
                .isMobile &{
                    font-size: 18px;
                }
            }
            &-button{
                font-size: 24px; 
                padding: 5px 10px;
                background: #fff1;
                border: none;
                margin-top: 10px;
                
            }
        }
    }
}

.lazy-loading{
    background: #203244;
}
