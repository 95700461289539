
@mixin BODYCSS(){
    &{ 
        overflow: hidden; 
        word-wrap: break-word;
        // word-break: break-all; 
        font-size: 16px; 
        line-height: 1.5;
        overflow-wrap: anywhere;
        word-break: break-word;
    }
    *{
        max-width: 100%;
    }
    pre{
        white-space: normal;
    }
    a{
        color: #71b5fd;
        text-decoration: underline;
        text-decoration-color: #71b5fd;
    } 
    strong {
        color: #62b4e2;
    }
    a:hover{
        text-decoration: underline;
    } 
    img{ 
        &:not(.nw){
            &:not(.pr){
                width: 100%;
            }
        }
        height: auto;
        vertical-align: middle;
        overflow: hidden;
        border-radius: 10px;
        margin: .5rem 0px;
        &.pr{
            float: left;
            width: 40%;
            margin-right: 5px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    img:hover{
        filter: none;
    }
    
    &{
        p {
            margin: 10px 0px 10px 0px;
        }
        > p:first-of-type{
            margin-top: 0px;
        }
        > p:last-of-type{
            margin-bottom: 0px;
        }
        > p:first-of-type{
            > div:first-of-type > img:first-of-type{
                margin-top: 0px;
            }
        }
        // > div:first-of-type > img:first-of-type{
        //     margin-top: 5px;
        // }
        > p:last-of-type{
            > div:last-of-type > img:last-of-type{
                margin-bottom: 8px;
            }
        }
        > div:last-of-type > img:last-of-type{
            margin-bottom: 5px;
        }
    }

    blockquote{
        position: relative;
        color: #5f90c1;
        /* font-size: 14px; */
        font-style: italic;
        font-weight: normal;
        margin: 10px 15px;
        background: #0d1217;
        padding: 15px 15px;
        padding-left: 50px !important;
        border-radius: 10px;
        > p{
            margin: 0px !important; 
        }
        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='30' height='30'%0AviewBox='0 0 172 172'%0Astyle=' fill:%23000000;'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'%3E%3C/path%3E%3Cg fill='%23233648'%3E%3Cpath d='M6.88,30.96v68.8h37.84v6.88c0,13.27625 -7.71312,24.08 -17.2,24.08h-3.44v24.08h3.44c26.5525,0 48.16,-21.6075 48.16,-48.16v-75.68zM96.32,30.96v68.8h37.84v6.88c0,13.27625 -7.71312,24.08 -17.2,24.08h-3.44v24.08h3.44c26.5525,0 48.16,-21.6075 48.16,-48.16v-75.68z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            object-fit: cover;
        }
        ol > li{
            margin-left: 1rem;
        }
    } 
    code{
        font-size: 87.5%;
        color: #e83a3a;
        word-wrap: break-word;  
        padding: 9px 10px 10px 10px;
        border-top: 1px solid #821919; 
        background: #0d1217;
        border-radius: 5px;
        display: block;
    }
    iframe{
        border-style: none;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .aspectratio{
        margin: 10px 0px;
        width: 100%;
        position: relative;
    }
    .aspectratio-child{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    video{
        margin: 10px 0px;
        width: 100%;
        height: auto; 
        outline: none;border-radius: 5px;
        display: block;
    }

    video:not(.video:first-child){
        margin-top: 10px;
    }
    h1,h2,h3,h4,h5,h6{
        margin-bottom: initial;
        font-weight: 500;
    }
    h1, .h1 {
        display: block;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 2.0rem;
    }
    h2, .h2 {
        display: block;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1.83rem;
    }
    h3, .h3 {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1.65rem;
    }
    h4, .h4 {
        display: block; 
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1.40625rem;
    }
    h5, .h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        font-size: 1.171875rem;
    }
    h6, .h6 {
        display: block;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 0.9375rem;
    }
    

    @media (min-width: 992px)
    {
        .col-lg-6 {
            flex: inherit; 
            max-width: inherit; 
        }
    }
    @media (min-width: 768px)
    {
        .col-md-12 {
            flex: inherit;
            max-width: inherit;
        }
    }
    @media (min-width: 576px)
    {
        .col-sm-12 {
            flex: inherit;
            max-width: inherit;
        }
    }
    .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
        position: relative;
        width: inherit;  
    }

    acronym[type="hex"]{
        color: #ff1a62;
        background: #0a0e17;
        padding: 5px 8px;
        padding-top: 7px;
        border-radius: 5px;
        display: block;
        font-size: 14px;
        line-height: 130%;
    }  
    .nosoport {
        background: #FFF1;
        padding: 9px 10px 5px px;
        border-radius: 5px;
    }
} 