.page-register {
    @include _PANEL();
    form{
        @include _FORM();
    }
    label.message_ok.color_green {
        color: #00ffb8;
    }
    .custom-checkbox label {
        color: $text_color !important;
        // padding-top: 1px;
    } 
    // .isMobile & .protocolo_rapido{
    //     display: none;;
    // }
}
